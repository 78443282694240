import { Component, HostBinding, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-collapsed-text',
  template: '<ng-content></ng-content>',
  styleUrls: ['./collapsed-text.component.scss'],
})
export class CollapsedTextComponent {
  @Input() maxHeight = 50;
  expanded = false;

  @HostBinding('class.expanded') get isExpanded() {
    return this.expanded;
  }

  @HostBinding('style.cursor') cursor = 'pointer';

  @HostBinding('style.display') display = 'block';

  @HostBinding('style.overflow') get overflow(): string {
    return this.expanded ? 'visible' : 'hidden';
  }

  @HostBinding('style.maxHeight.px') get hostMaxHeight(): number {
    return this.expanded ? 1000 : this.maxHeight;
  }

  @HostListener('click')
  toggleExpand(): void {
    this.expanded = !this.expanded;
  }
}
