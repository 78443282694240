import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { LotAddSharedListModalButtonComponent } from './lot-add-shared-list-modal-button.component';
import { LotAddSharedListModalComponent } from './components/lot-add-shared-list-modal/lot-add-shared-list-modal.component';
import { LotSharedAddListButtonModule } from '../lot-shared-add-list-button/lot-shared-add-list-button.module';

@NgModule({
  declarations: [LotAddSharedListModalButtonComponent, LotAddSharedListModalComponent],
  imports: [CommonModule, NzButtonModule, TranslateModule, LotSharedAddListButtonModule],
  exports: [LotAddSharedListModalButtonComponent],
})
export class LotAddSharedListModalButtonModule {}
