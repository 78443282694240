import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotAddNoteButtonComponent } from './lot-add-note-button.component';
import { LotAddNoteFormModalComponent } from './components/lot-add-note-form-modal/lot-add-note-form-modal.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule } from 'ng-zorro-antd/modal';

@NgModule({
  declarations: [LotAddNoteButtonComponent, LotAddNoteFormModalComponent],
  imports: [
    CommonModule,
    NzAlertModule,
    NzButtonModule,
    NzDividerModule,
    NzImageModule,
    NzInputModule,
    NzSkeletonModule,
    NzWaveModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NzModalModule,
  ],
  exports: [LotAddNoteButtonComponent],
})
export class LotAddNoteButtonModule {}
