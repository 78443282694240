<section
  appDriverStep
  [overrideStepIndex]="3"
  [stepTitle]="'AUTO_LOT.GUIDE.ACTION_BTNS.FIRST.TITLE' | translate"
  [stepDescription]="'AUTO_LOT.GUIDE.ACTION_BTNS.FIRST.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'auto-lot'"
>
  <div
    appDriverStep
    [overrideStepIndex]="4"
    [stepTitle]="'AUTO_LOT.GUIDE.ACTION_BTNS.SECOND.TITLE' | translate"
    [stepDescription]="'AUTO_LOT.GUIDE.ACTION_BTNS.SECOND.DESCR' | translate"
    [stepSide]="'bottom'"
    [stepAlign]="'start'"
    [popoverClass]="'auto-lot'"
  >
    <div class="buttons">
      <ng-container *ngTemplateOutlet="placeBidAction"></ng-container>
      <ng-container *ngTemplateOutlet="mainActions"></ng-container>
    </div>

    <div class="buttons">
      <ng-container *ngTemplateOutlet="sideActions"></ng-container>
    </div>

    <div class="mobile-buttons">
      <button
        class="open-other-actions-btn"
        nz-button
        nzShape="circle"
        (click)="actionsDrawerOpened = true"
      >
        <i nz-icon nzType="menu"></i>
      </button>

      <ng-container *ngTemplateOutlet="placeBidAction; context: { fullSize: true }"></ng-container>
    </div>
  </div>
</section>

<nz-drawer
  [nzClosable]="true"
  [nzVisible]="actionsDrawerOpened"
  nzPlacement="right"
  [nzTitle]="'ACTIONS' | translate"
  [nzCloseOnNavigation]="true"
  (nzOnClose)="actionsDrawerOpened = false"
>
  <ng-container *nzDrawerContent>
    <div class="drawer-action">
      <ng-container *ngTemplateOutlet="mainActions; context: { fullSize: true }"></ng-container>
      <ng-container *ngTemplateOutlet="sideActions; context: { fullSize: true }"></ng-container>
    </div>
  </ng-container>
</nz-drawer>

<ng-template #placeBidAction let-fullSize="fullSize">
  <app-lot-place-bid-button
    *ngIf="isActionAvailable(LotActionEnum.PlaceBid)"
    [lot]="lot"
    [limits]="limits"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [fullSize]="fullSize"
    [hasTranslations]="hasTranslations"
    [canRecognizeAuctionList]="savedLot.canRecognizeAuctionList"
    [currentUserLimitsOverrideAvailable]="currentUserLimitsOverrideAvailable"
    [currentUserLastBid]="currentUserLastBid"
    (afterSubmit)="actionCompleted.emit()"
  ></app-lot-place-bid-button>
</ng-template>

<ng-template #mainActions let-fullSize="fullSize">
  <app-share-button
    *ngIf="isActionAvailable(LotActionEnum.Share)"
    [fullSize]="fullSize"
    [savedLot]="savedLot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    (lotShared)="actionCompleted.emit()"
  ></app-share-button>
  <app-lot-translate-button
    *ngIf="isActionAvailable(LotActionEnum.Translate)"
    [fullSize]="fullSize"
    [lot]="lot"
    [lotType]="lotType"
    [lotSource]="lotSource"
  ></app-lot-translate-button>
  <app-lot-add-photo-button
    *ngIf="isActionAvailable(LotActionEnum.AddPhoto)"
    [fullSize]="fullSize"
    [lot]="lot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [currentUser]="currentUser"
    (finish)="actionCompleted.emit()"
  ></app-lot-add-photo-button>
  <app-lot-bids
    *ngIf="isActionAvailable(LotActionEnum.Bids)"
    [fullSize]="fullSize"
    [bids]="bids"
    [currentUser]="currentUser"
  ></app-lot-bids>
  <app-lot-events-modal
    *ngIf="isActionAvailable(LotActionEnum.Events)"
    [fullSize]="fullSize"
    [lot]="savedLot"
    [currentUser]="currentUser"
  ></app-lot-events-modal>
  <app-lot-reset-button
    *ngIf="isActionAvailable(LotActionEnum.Reset)"
    [fullSize]="fullSize"
    [lot]="lot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [currentUser]="currentUser"
    (resetCompleted)="actionCompleted.emit()"
  ></app-lot-reset-button>
  <app-purchase-report-button
    *ngIf="isActionAvailable(LotActionEnum.PurchaseReport)"
    [fullSize]="fullSize"
    [lot]="lot"
    [lotType]="lotType"
  ></app-purchase-report-button>
  <app-lot-edit-auction-sheet-zones
    *ngIf="isActionAvailable(LotActionEnum.EditAuctionSheetZones)"
    [fullSize]="fullSize"
    [lot]="lot"
    [currentUser]="currentUser"
  ></app-lot-edit-auction-sheet-zones>
</ng-template>

<ng-template #sideActions let-fullSize="fullSize">
  <app-lot-create-translation-request-button
    *ngIf="isActionAvailable(LotActionEnum.CreateTranslationRequest)"
    [fullSize]="fullSize"
    [currentUser]="currentUser"
    [lot]="lot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [hasTranslations]="hasTranslations"
    (translationRequestCreated)="actionCompleted.emit()"
    (clickIfTranslationsExists)="scrollToTranslations.emit()"
  ></app-lot-create-translation-request-button>
  <app-lot-get-photo-request-button
    *ngIf="isActionAvailable(LotActionEnum.GetPhotoRequest)"
    [fullSize]="fullSize"
    [lot]="lot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    (sendComplete)="actionCompleted.emit()"
  ></app-lot-get-photo-request-button>
  <app-lot-notations-button
    *ngIf="isActionAvailable(LotActionEnum.Notations)"
    [fullSize]="fullSize"
  ></app-lot-notations-button>
  <app-lot-watch-request-button
    [savedLot]="savedLot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [fullSize]="fullSize"
    [isLotSanctioned]="isLotSanctioned"
    (lotShared)="actionCompleted.emit()"
  ></app-lot-watch-request-button>
  <app-lot-add-note-button
    *ngIf="!lot.currentUserLotNote"
    [lotId]="lot.id"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [fullSize]="fullSize"
    [isUpdate]="false"
    (actionCompleted)="actionCompleted.emit()"
  ></app-lot-add-note-button>
  <app-lot-add-shared-list-modal-button
    [lot]="lot"
    [lotType]="lotType"
    [lotSource]="lotSource"
    [isUpdate]="false"
    [fullSize]="fullSize"
    (actionCompleted)="actionCompleted.emit()"
  ></app-lot-add-shared-list-modal-button>
</ng-template>
