import { AkebonoCoreModule } from '@akebono/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TranslateModule } from '@ngx-translate/core';
import { NavUserProfileComponent } from './nav-user-profile.component';

@NgModule({
  declarations: [NavUserProfileComponent],
  imports: [
    CommonModule,
    AkebonoCoreModule,
    NzButtonModule,
    NzDropDownModule,
    NzIconModule,
    TranslateModule,
  ],
  exports: [NavUserProfileComponent],
})
export class NavUserProfileModule {}
