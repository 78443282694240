import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LotAddNoteFormModalComponent } from './components/lot-add-note-form-modal/lot-add-note-form-modal.component';
import { LotSource, LotTypeEnum } from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-add-note-button',
  templateUrl: './lot-add-note-button.component.html',
  styleUrls: ['./lot-add-note-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAddNoteButtonComponent {
  @Input() lotId: string;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() fullSize = false;
  @Input() isUpdate = false;
  @Output() actionCompleted = new EventEmitter<void>();

  constructor(
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {}

  openLotAddNoteFormModal(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_ADD_NOTE.ADD_TITLE'),
      nzContent: LotAddNoteFormModalComponent,
      nzWidth: this.fullSize ? '95%' : '60%',
      nzComponentParams: {
        lotId: this.lotId,
        lotType: this.lotType,
        lotSource: this.lotSource,
        isUpdate: this.isUpdate,
        actionCompleted: this.actionCompleted,
      },
    });
  }
}
