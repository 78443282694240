<nz-layout style="min-height: 100vh">
  <!-- <p class="info-title">Технические работы</p> -->
<!--  <app-new-year-decoration></app-new-year-decoration>-->

  <nz-page-header>
    <nz-page-header-content>
      <nz-row nzJustify="space-between" nzAlign="middle" [nzGutter]="[5, 10]">
        <nz-col>
          <nz-row nzAlign="middle" [nzGutter]="[40, 0]">
            <nz-col>
              <a [routerLink]="['/']">
                <nz-row nzAlign="middle">
                  <nz-col>
                    <nz-page-header-title> ECar JP</nz-page-header-title>
                  </nz-col>
                  <nz-col>
                    <nz-avatar
                      nz-page-header-avatar
                      nzSrc="/assets/logo_transparent.png"
                      style="margin-right: 5px"
                    ></nz-avatar>
                  </nz-col>
                </nz-row>
              </a>
            </nz-col>
            <nz-col *ngIf="isDesktop$ | async">
              <nz-row>
                <nz-col>
                  <nz-page-header-subtitle>
                    <a
                      nz-button
                      nzType="link"
                      [routerLink]="['/bids']"
                      [queryParams]="{
                        bidLotAuctionDateFrom: DateTime.now().toFormat('yyyy-MM-dd')
                      }"
                      style="padding: 0"
                    >
                      {{ 'MY_BIDS' | translate }}
                    </a>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col>
                  <nz-page-header-subtitle>
                    <app-news-counter [offset]="[3, 10]">
                      <a nz-button nzType="link" [routerLink]="['/news']" style="padding: 0">
                        {{ 'NEWS' | translate }}
                      </a>
                    </app-news-counter>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col>
                  <nz-page-header-subtitle>
                    <button
                      nz-button
                      nzType="link"
                      (click)="createLotManually()"
                      style="padding: 0"
                    >
                      {{ 'LOT_MANUALLY' | translate }}
                    </button>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col>
                  <nz-page-header-subtitle>
                    <a nz-button nzType="link" [routerLink]="['/selections']" style="padding: 0">
                      {{ 'SELECTIONS' | translate }}
                    </a>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col *akbCheck="['cars.boundary-lots']">
                  <nz-page-header-subtitle>
                    <a nz-button nzType="link" [routerLink]="['/boundary-lots']" style="padding: 0">
                      {{ 'BOUNDARY_LOTS' | translate }}
                    </a>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col>
                  <nz-page-header-subtitle>
                    <button
                      nz-button
                      nzType="link"
                      style="padding: 0"
                      (click)="notificationSettings()"
                    >
                      {{ 'SETTINGS.TITLE' | translate }}
                    </button>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col>
                  <nz-page-header-subtitle>
                    <a
                      nz-button
                      nzType="link"
                      [routerLink]="['/article', 'faq']"
                      style="padding: 0"
                    >
                      F.A.Q.
                    </a>
                  </nz-page-header-subtitle>
                </nz-col>
                <nz-col>
                  <nz-page-header-subtitle>
                    <button nz-button nzType="link" style="padding: 0" routerLink="/notifications">
                      {{ 'SETTINGS.NOTIFICATIONS' | translate }}
                    </button>
                  </nz-page-header-subtitle>
                </nz-col>
              </nz-row>
            </nz-col>
          </nz-row>
        </nz-col>
        <nz-col *ngIf="isMobile$ | async">
          <app-news-counter>
            <button nz-button nzSize="large" (click)="mobileMenuOpened = true">
              <i nz-icon nzType="menu" nzTheme="outline"></i>
            </button>
          </app-news-counter>
        </nz-col>
        <nz-col [nzSpan]="(isMobile$ | async) ? 24 : null">
          <div style="margin-right: 10px; display: inline-block">
            <span class="header-currencies" *ngIf="exchangeRates$ | async as exchangeRates">
              {{ 1 | currency: '₽':'symbol':'1.0-0' }} =
              {{ exchangeRates.jpyToRub | currency: 'JPY':'symbol':'1.2-2' }}
              <span style="color: red">|</span> 
              {{ 1 | currency: 'USD':'symbol':'1.0-0' }} =
              {{ exchangeRates.jpyToUsd | currency: 'JPY':'symbol':'1.2-2' }}
              <span style="color: red">|</span> {{ 1 | currency: 'EUR':'symbol':'1.0-0' }} =
              {{ exchangeRates.jpyToEur | currency: 'JPY':'symbol':'1.2-2' }}
            </span>
            <nz-skeleton-element
              *ngIf="loading$ | async"
              nzType="input"
              [nzActive]="true"
              nzSize="default"
            ></nz-skeleton-element>
          </div>
          <ng-container *ngIf="isDesktop$ | async">
            <app-language-selector></app-language-selector>
            <app-nav-user-profile></app-nav-user-profile>
          </ng-container>
        </nz-col>
        <nz-col [nzSpan]="24">
          <nz-page-header-subtitle>
            TOKYO
            <span class="text-green">
              {{ clock$ | async | date: 'fullTime':'+0900':'ru' }}
            </span>
          </nz-page-header-subtitle>
        </nz-col>
        <nz-col>
          <nz-breadcrumb [nzAutoGenerate]="true" [nzRouteLabelFn]="translateFn" nz-page-header-breadcrumb>
            <nz-breadcrumb-item *ngIf="layoutService.isShowBackIcon$ | async">
              <a [routerLink]="['/']">{{ 'HOME' | translate }}</a>
            </nz-breadcrumb-item>
          </nz-breadcrumb>
        </nz-col>
      </nz-row>
    </nz-page-header-content>
  </nz-page-header>

  <nz-content class="wrap">
    <router-outlet></router-outlet>
  </nz-content>
</nz-layout>

<nz-drawer
  nzPlacement="right"
  [nzClosable]="true"
  [nzTitle]="'MENU' | translate"
  [nzCloseOnNavigation]="true"
  [nzVisible]="mobileMenuOpened"
  (nzOnClose)="mobileMenuOpened = false"
>
  <ng-container *nzDrawerContent>
    <nz-row [nzGutter]="[10, 15]">
      <nz-col [nzSpan]="24">
        <ul nz-menu [nzMode]="'vertical'">
          <li nz-menu-item [nzMatchRouter]="true">
            <a
              [routerLink]="['/bids']"
              [queryParams]="{
                bidLotAuctionDateFrom: DateTime.now().toFormat('yyyy-MM-dd')
              }"
            >
              {{ 'MY_BIDS' | translate }}
            </a>
          </li>
          <li nz-menu-item [nzMatchRouter]="true">
            <app-news-counter [style.margin.px]="0" [offset]="[4, 2]">
              <a [routerLink]="['/news']">
                {{ 'NEWS' | translate }}
              </a>
            </app-news-counter>
          </li>
          <li nz-menu-item [nzMatchRouter]="true">
            <a (click)="createLotManually()">
              {{ 'LOT_MANUALLY' | translate }}
            </a>
          </li>
          <li nz-menu-item [nzMatchRouter]="true">
            <a [routerLink]="['/selections']">
              {{ 'SELECTIONS' | translate }}
            </a>
          </li>
          <li *akbCheck="['cars.boundary-lots']" nz-menu-item [nzMatchRouter]="true">
            <a [routerLink]="['/boundary-lots']">
              {{ 'BOUNDARY_LOTS' | translate }}
            </a>
          </li>
          <li nz-menu-item [nzMatchRouter]="true">
            <a (click)="notificationSettings()">
              {{ 'SETTINGS.TITLE' | translate }}
            </a>
          </li>
          <li nz-menu-item [nzMatchRouter]="true">
            <a [routerLink]="['/article', 'faq']"> F.A.Q. </a>
          </li>
          <li nz-menu-item [nzMatchRouter]="true">
            <a [routerLink]="['/notifications']">
              {{ 'SETTINGS.NOTIFICATIONS' | translate }}
            </a>
          </li>
        </ul>
      </nz-col>

      <nz-col [nzSpan]="24">
        <nz-space>
          <app-language-selector *nzSpaceItem></app-language-selector>
          <app-nav-user-profile *nzSpaceItem></app-nav-user-profile>
        </nz-space>
      </nz-col>
    </nz-row>
  </ng-container>
</nz-drawer>
