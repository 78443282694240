<section
  appDriverStep
  [overrideStepIndex]="8"
  [stepTitle]="'AUTO_LOT.GUIDE.LOT_DATA.TITLE' | translate"
  [stepDescription]="'AUTO_LOT.GUIDE.LOT_DATA.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'auto-lot'"
>
  <h4 class="section-title">
    {{ 'LOT_INFO.OBJECT_DATA' | translate }}
  </h4>

  <nz-row [nzGutter]="rowGutters">
    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      {{ lot.company }} {{ lot.modelName }}
      <app-lot-edit-model-brand
        *ngIf="currentUser?.canUpdateLotInfo && lotSource === 'manual'"
        [lot]="lot"
        [lotType]="lotType"
        [lotSource]="lotSource"
        (actionComplete)="actionComplete.emit()"
      ></app-lot-edit-model-brand>
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      {{ lot.color || '—' }}
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      <span *ngIf="lot.rate; else noValue" class="warning">
        {{ lot.rate }}
        <i nz-icon nzType="star" nzTheme="fill"></i>
      </span>
      <app-lot-edit-rating
        *ngIf="currentUser?.canUpdateLotInfo && lotSource === 'manual'"
        [lot]="lot"
        [lotType]="lotType"
        [lotSource]="lotSource"
        (actionComplete)="actionComplete.emit()"
      ></app-lot-edit-rating>
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      <ng-container *ngIf="lotType !== LotType.Moto">
        {{ lot.frame || '—' }}
      </ng-container>
      <ng-container *ngIf="lotType === LotType.Moto">
        {{ lot.vin || lot.frame }}
      </ng-container>
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      {{ lot.mileage || '—' }}
      <ng-container *ngIf="lotType === LotType.Moto">km</ng-container>
      <ng-container *ngIf="lotType !== LotType.Moto">
        {{ mileagePostfix }}
      </ng-container>
    </nz-col>

    <nz-col *ngIf="lot.modelGrade">
      <span class="secondary" [innerHTML]="lot.modelGrade || '—'"></span>
    </nz-col>

    <nz-col *ngIf="lot.year && lot.year !== '0'" [nzXs]="12" [nzSm]="12" [nzMd]="8">
      {{ lot.year }}
      <app-lot-edit-year
        *ngIf="currentUser?.canUpdateLotInfo && lotSource === 'manual'"
        [lot]="lot"
        [lotType]="lotType"
        [lotSource]="lotSource"
        (actionComplete)="actionComplete.emit()"
      ></app-lot-edit-year>
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      <ng-container *ngIf="lot.engineVolume; else noValue">
        {{ lot.engineVolume }} cc
      </ng-container>
      <app-lot-edit-engine-volume
        *ngIf="currentUser?.canEditEngineVolume"
        [lot]="lot"
        [lotType]="lotType"
        [lotSource]="lotSource"
        (actionComplete)="actionComplete.emit()"
      ></app-lot-edit-engine-volume>
    </nz-col>

    <nz-col *ngIf="lot.productionDate" [nzXs]="12" [nzSm]="12" [nzMd]="8">
      {{ lot.productionDate | date: 'yyyy-MM-dd' }}
      <div *ngIf="!forShared && !currentUser?.isOtherCountries">
        <app-lot-boundary-info [lot]="lot"></app-lot-boundary-info>
      </div>
    </nz-col>

    <nz-col *ngIf="lot.transmission" [nzXs]="12" [nzSm]="12" [nzMd]="8">
      <span class="danger">{{ lot.transmission }}</span>
      <ng-container *ngIf="gearboxType">
        / {{ 'GEARBOX_TYPES.' + gearboxType | translate }}
      </ng-container>
    </nz-col>
  </nz-row>
</section>

<section
  appDriverStep
  [overrideStepIndex]="9"
  [stepTitle]="'AUTO_LOT.GUIDE.AUCTION_DATA.TITLE' | translate"
  [stepDescription]="'AUTO_LOT.GUIDE.AUCTION_DATA.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'auto-lot'"
>
  <h4 class="section-title">
    {{ 'LOT_INFO.AUCTION' | translate }}
  </h4>

  <nz-row [nzGutter]="rowGutters">
    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      <app-lot-auction-name [auctionName]="lot.auctionName"></app-lot-auction-name>
      <app-lot-auction-rating
        [auctionName]="lot.auctionName"
        [showComments]="!forShared"
      ></app-lot-auction-rating>
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
      <nz-countdown
        *ngIf="isActiveLot$ | async"
        [nzValue]="deadlineLotTime"
        ngClass="{{ (isEndSoon$ | async) ? 'red-countdown' : '' }}"
      ></nz-countdown>
      <span>{{ lot.time | date: 'yyyy-MM-dd HH:mm':'+0900' }}</span>
      <span *ngIf="(isEndSoon$ | async) && (isActiveLot$ | async)" class="end-soon-text">
        {{ 'LOT_INFO.END_SOON_HINT' | translate }}
      </span>
      <strong *ngIf="(isActiveLot$ | async) === false" class="end-soon-text">
        {{ 'LOT_INFO.BIDDING_OVER' | translate }}
      </strong>
    </nz-col>

    <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
      {{ lot.time | date: 'yyyy-MM-dd H:mm:ss zz':'+0900' }}
      <app-lot-edit-auction-datetime
        *ngIf="currentUser?.canEditStartPrice"
        [lot]="lot"
        [lotType]="lotType"
        [lotSource]="lotSource"
        (actionComplete)="actionComplete.emit()"
      ></app-lot-edit-auction-datetime>
    </nz-col>

    <nz-col *ngIf="lot.status" [nzXs]="12" [nzSm]="12" [nzMd]="16">
      <span class="warning">
        {{ 'LOT_STATUSES.' + lot.status | uppercase | translate }}
      </span>
    </nz-col>
  </nz-row>
</section>

<div
  appDriverStep
  [overrideStepIndex]="10"
  [stepTitle]="'AUTO_LOT.GUIDE.BIDS.FIRST.TITLE' | translate"
  [stepDescription]="'AUTO_LOT.GUIDE.BIDS.FIRST.DESCR' | translate"
  [stepSide]="'left'"
  [stepAlign]="'start'"
  [popoverClass]="'auto-lot'"
>
  <section
    appDriverStep
    [overrideStepIndex]="11"
    [stepTitle]="'AUTO_LOT.GUIDE.BIDS.SECOND.TITLE' | translate"
    [stepDescription]="'AUTO_LOT.GUIDE.BIDS.SECOND.DESCR' | translate"
    [stepSide]="'bottom'"
    [stepAlign]="'start'"
    [popoverClass]="'auto-lot'"
  >
    <h4 class="section-title">
      {{ 'LOT_INFO.BIDS' | translate }}
    </h4>

    <nz-row [nzGutter]="rowGutters">
      <nz-col [nzXs]="24">
        <nz-row [nzGutter]="innerRowGutters">
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
          <span *ngIf="lotType !== LotType.Oneprice; else onePriceStart" class="secondary">
            {{ 'LOT_INFO.START_PRICE' | translate }}
          </span>
            <ng-template #onePriceStart>
              {{ 'LOT_INFO.FIXED_PRICE' | translate }}
            </ng-template>
          </nz-col>
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
            <nz-row [nzGutter]="innerRowGutters">
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
                {{ lot.startPrice | currency: (currentCurrency$ | async)?.name:'symbol-narrow' }}
                <app-lot-edit-start-price
                  *ngIf="currentUser?.canEditStartPrice"
                  [lot]="lot"
                  [lotType]="lotType"
                  [lotSource]="lotSource"
                  (actionComplete)="actionComplete.emit()"
                ></app-lot-edit-start-price>
              </nz-col>
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
                <app-lot-bid-and-bidders-counter [counter]="lot"></app-lot-bid-and-bidders-counter>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
      </nz-col>

      <nz-col [nzSpan]="24">
        <nz-row [nzGutter]="innerRowGutters">
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
          <span class="secondary">
            {{
              (isAveragePrice ? 'LOT_INFO.AVERAGE_PRICE' : 'LOT_INFO.RECOMMENDED_BID_AMOUNT')
                | translate
            }}
          </span>
          </nz-col>
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
            <ng-container
              *rxLet="recommendedBid$ as recommendedBid"
              [class.red]="recommendedBid > 5900000"
            >
              {{ recommendedBid > 0 ? (recommendedBid | currency: 'JPY':'symbol-narrow') : '—' }}
            </ng-container>
          </nz-col>
        </nz-row>
      </nz-col>

      <nz-col [nzSpan]="24" *ngIf="currentUserLastBid">
        <nz-row [nzGutter]="innerRowGutters" nzAlign="middle">
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
          <span class="your-bid">
            {{ 'LOT_INFO.YOUR_BID' | translate }}
          </span>
          </nz-col>
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
            <nz-row [nzGutter]="innerRowGutters" nzAlign="middle">
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
                <div class="your-bid">
                  <app-lot-bid-amount [bid]="currentUserLastBid"></app-lot-bid-amount>
                </div>
                <div *ngIf="currentUserLastBid.wasOverbid" class="red">
                  {{ 'LOT_INFO.WAS_OVERBID' | translate }}
                </div>
                <app-bid-priority-pass-status
                  [bid]="currentUserLastBid"
                ></app-bid-priority-pass-status>
                <nz-tag *ngIf="currentUserLastBid?.isGreenCorner" [nzColor]="'green'">
                  {{ 'LOT_BIDDING_MODAL.GREEN_CORNER' | translate }}
                </nz-tag>
              </nz-col>
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
                <div *ngIf="currentUserLastBid.bidGroup">
                  ({{ currentUserLastBid.bidGroup.letter }}-{{
                    currentUserLastBid.bidGroup.quantity
                  }}
                  {{ currentUserLastBid.bidGroup.date | date: 'dd.MM' }})
                </div>
                <app-lot-participation-button
                  [bid]="currentUserLastBid"
                  [hasTranslations]="hasTranslations"
                  (actionComplete)="actionComplete.emit()"
                ></app-lot-participation-button>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
      </nz-col>

      <nz-col *ngIf="canREnderNegotiationsPrice" [nzSpan]="24">
        <nz-row [nzGutter]="innerRowGutters">
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
            {{ 'LOT_INFO.NEGOTIATIONS_PRICE' | translate }}
          </nz-col>
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
            <nz-row [nzGutter]="innerRowGutters">
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
              <span class="fw-600">
                {{ lot.negotiationsPrice | currency: 'JPY':'symbol-narrow':'1.0-0':'en-US' }}
              </span>
              </nz-col>
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
                <app-lot-negotiations-button
                  [currentUserLastBid]="currentUserLastBid"
                  [negotiationsPriceStr]="lot.negotiationsPrice"
                  [isOtherCountriesUser]="currentUser.isOtherCountries"
                  (actionComplete)="actionComplete.emit()"
                ></app-lot-negotiations-button>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
      </nz-col>

      <nz-col *ngIf="!forShared" [nzSpan]="24">
        <nz-row *ngIf="canRenderBestBid" [nzGutter]="innerRowGutters">
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
            {{ 'LOT_INFO.BEST_BID' | translate }}
          </nz-col>
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
          <span class="best-bid">
            <app-lot-bid-amount [bid]="bestBid"></app-lot-bid-amount>
          </span>
            <div *ngIf="bestBid.user?.fullName && currentUser.canSeeBestBidAdmin">
              {{ bestBid.user?.fullName }}
            </div>
          </nz-col>
        </nz-row>

        <nz-row *ngIf="!bestBid" [nzGutter]="innerRowGutters">
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="8">
            {{ 'LOT_INFO.BEST_BID' | translate }}
          </nz-col>
          <nz-col [nzXs]="12" [nzSm]="12" [nzMd]="16">
            <nz-row [nzGutter]="innerRowGutters">
              <nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
              <span *ngIf="!hasBestBid" class="green">
                {{ 'LOT_INFO.NOT_EXISTS' | translate }}
              </span>
                <span *ngIf="hasBestBid" class="red bid-exists">
                {{ 'LOT_INFO.EXISTS' | translate }}
              </span>
              </nz-col>
              <nz-col *ngIf="hasBestBid" [nzXs]="24" [nzSm]="24" [nzMd]="12">
                <app-subscribe-to-best-bid-button
                  [subscriptionData]="currentUser"
                  (subscriptionCreated)="actionComplete.emit()"
                ></app-subscribe-to-best-bid-button>
              </nz-col>
            </nz-row>
          </nz-col>
        </nz-row>
      </nz-col>

      <nz-col *ngIf="lot.result || lot.finishPrice" [nzSpan]="24">
        <nz-row [nzGutter]="innerRowGutters">
          <nz-col [nzSpan]="8">
            {{ 'LOT_INFO.TRADING_RESULT' | translate }}
          </nz-col>
          <nz-col [nzSpan]="16">
          <span class="fw-600">
            <ng-container *ngIf="!(forShared && lot.auctionName.startsWith('USS'))">
              {{ lot.finishPrice | currency: 'JPY':'symbol-narrow' }}
            </ng-container>
            <ng-container *ngIf="lot.result">
              {{ lot.result | translate }}
            </ng-container>
          </span>
          </nz-col>
        </nz-row>
      </nz-col>
    </nz-row>
  </section>
</div>

<ng-template #noValue>—</ng-template>
