<button
  nz-button
  nz-dropdown
  [nzDropdownMenu]="userName"
  nzTrigger="click"
  [nzLoading]="loading$ | async"
>
  <span><i nz-icon nzType="user"></i></span>
  <i nz-icon nzType="down"></i>
</button>
<nz-dropdown-menu #userName="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item>
      {{ userLogin$ | async }}
    </li>
    <li nz-menu-item>
      <button nz-button (click)="signOut()">{{ 'SIGN_OUT' | translate }}</button>
    </li>
  </ul>
</nz-dropdown-menu>
