import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AutoLotView } from '../../../../../const';
import {
  LotSource,
  LotTypeEnum,
  UserLotMark,
} from '../../../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-add-shared-list-modal',
  templateUrl: './lot-add-shared-list-modal.component.html',
  styleUrls: ['./lot-add-shared-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAddSharedListModalComponent {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() userLotMarks: UserLotMark[];
}
