import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { MutationHandlingServiceV2 } from '@akebono/core';
import { finalize } from 'rxjs/operators';
import {
  LotNote,
  LotNoteCreateGQL,
  LotNoteUpdateGQL,
  LotSource,
  LotTypeEnum,
} from '../../../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-lot-add-note-form-modal',
  templateUrl: './lot-add-note-form-modal.component.html',
  styleUrls: ['./lot-add-note-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAddNoteFormModalComponent implements OnInit {
  @Input() lotId: string;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() lotNote: LotNote;
  @Input() isUpdate = false;
  @Output() actionCompleted = new EventEmitter<void>();

  loading$: Observable<boolean>;

  note = '';

  constructor(
    private readonly modal: NzModalRef,
    private readonly mhs: MutationHandlingServiceV2,
    private readonly lotNoteCreateGQL: LotNoteCreateGQL,
    private readonly lotNoteUpdateGQL: LotNoteUpdateGQL,
  ) {}

  ngOnInit(): void {
    if (this.isUpdate) {
      this.note = this.lotNote.note;
    }
  }

  createOrUpdateNote(): void {
    const gql = this.isUpdate ? this.lotNoteUpdateGQL : this.lotNoteCreateGQL;
    const input = this.isUpdate
      ? { id: this.lotNote.id, note: this.note }
      : { lotId: this.lotId, lotType: this.lotType, lotSource: this.lotSource, note: this.note };

    this.loading$ = this.mhs.mutate<any, any>(gql, { input }).loading;
    this.loading$ = this.loading$.pipe(
      finalize(() => {
        this.actionCompleted.emit();
        this.close();
      }),
    );
  }

  close(): void {
    this.modal.destroy();
  }
}
