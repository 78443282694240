<nz-collapse>
  <nz-collapse-panel [nzHeader]="'NOTE' | translate" [nzExtra]="extraTpl">
    <div [innerHTML]="lotNote?.note ?? 'test'"></div>
  </nz-collapse-panel>
</nz-collapse>

<ng-template #extraTpl>
  <nz-row [nzGutter]="[10, 0]">
    <nz-col>
      <button
        nz-button
        nzSize="small"
        (click)="editNote($event)"
      >
        <i nz-icon nzType="edit" nzTheme="outline"></i>
      </button>
    </nz-col>
    <nz-col>
      <button
        nz-button
        nzSize="small"
        nzDanger
        nz-popconfirm
        nzType="primary"
        (click)="$event.stopPropagation()"
        [nzLoading]="isDeleting$ | async"
        (nzOnConfirm)="deleteNote()"
        [nzPopconfirmTitle]="'LOT_ADD_NOTE.DELETE_NOTE' | translate"
      >
        <i nz-icon nzType="delete" nzTheme="outline"></i>
      </button>
    </nz-col>
  </nz-row>
</ng-template>
