import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MutationHandlingServiceV2 } from '@akebono/core';
import { LotNote, LotNoteDeleteGQL } from '../../graphql/service/graphql-auto-main-service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LotAddNoteFormModalComponent } from '../lot-add-note-button/components/lot-add-note-form-modal/lot-add-note-form-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { finalize, map, shareReplay, takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-lot-note',
  templateUrl: './lot-note.component.html',
  styleUrls: ['./lot-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotNoteComponent implements OnDestroy {
  @Input() lotNote: LotNote;
  @Output() actionCompleted = new EventEmitter<void>();
  isMobile: boolean;
  isDeleting$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly mhs: MutationHandlingServiceV2,
    private readonly modalService: NzModalService,
    private readonly translate: TranslateService,
    private readonly lotNoteDeleteGQL: LotNoteDeleteGQL,
    breakpointObserver: BreakpointObserver,
  ) {
    breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(
        map((state) => state.matches),
        takeUntil(this.destroy$),
      )
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  editNote($event: MouseEvent): void {
    $event.stopPropagation();
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_ADD_NOTE.EDIT_TITLE'),
      nzContent: LotAddNoteFormModalComponent,
      nzWidth: this.isMobile ? '95%' : '60%',
      nzComponentParams: {
        lotNote: this.lotNote,
        isUpdate: true,
        actionCompleted: this.actionCompleted,
      },
    });
  }

  deleteNote(): void {
    this.isDeleting$ = this.mhs
      .mutate(this.lotNoteDeleteGQL, {
        input: {
          id: this.lotNote.id,
        },
      })
      .loading.pipe(
        finalize(() => {
          this.actionCompleted.emit();
        }),
        shareReplay(),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
