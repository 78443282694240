import { AuthenticationService, QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CurrentUserMinimalGQL,
  CurrentUserMinimalQuery,
  CurrentUserMinimalQueryVariables,
} from '../../graphql/service/graphql-auto-main-service';

@Component({
  selector: 'app-nav-user-profile',
  templateUrl: './nav-user-profile.component.html',
  styleUrls: ['./nav-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavUserProfileComponent {
  userLogin$: Observable<string>;
  loading$: Observable<boolean>;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private qhs: QueryHandlingService,
    private currentUserMinimalGQL: CurrentUserMinimalGQL,
  ) {
    const queryRef = this.qhs.fetch<CurrentUserMinimalQuery, CurrentUserMinimalQueryVariables>(
      this.currentUserMinimalGQL,
    );
    this.userLogin$ = queryRef.data.pipe(map((data) => data.currentUser.username));
    this.loading$ = queryRef.loading;
  }

  signOut(): void {
    this.authService.signOut();
    this.router.navigateByUrl('/sign-in');
  }
}
