<nz-skeleton [nzLoading]="loading$ | async" [nzActive]="true" [nzParagraph]="{ rows: 5 }">
  <ng-container *ngIf="alreadyCreated$ | async; else createRequestForm">
    <nz-alert
      *ngIf="(hasFiles$ | async) === false"
      nzType="warning"
      [nzMessage]="'WATCH_REQUEST.ALREADY_CREATED' | translate"
    ></nz-alert>

    <ng-container *ngIf="(hasFiles$ | async) && filesByType$ | async as files">
      <h4>{{ 'WATCH_REQUEST.IMAGES' | translate }}</h4>
      <nz-image-group *ngIf="files.images.length > 0">
        <div class="gallery">
          <img
            *ngFor="let image of files.images"
            nz-image
            width="150px"
            [nzSrc]="image"
            [alt]="'Lot image'"
          />
        </div>
      </nz-image-group>

      <nz-divider></nz-divider>

      <h4>{{ 'WATCH_REQUEST.VIDEOS' | translate }}</h4>
      <div *ngIf="files.videos.length > 0" class="video-gallery">
        <div *ngFor="let video of files.videos" class="video-container">
          <video
            controls
            width="300"
            [poster]="video.replace('.mp4', '.jpg')"
          >
            <source [src]="video" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #createRequestForm>
    <section>
      <nz-alert [nzMessage]="alertContent">
        <ng-template #alertContent>
          <div [innerHTML]="'WATCH_REQUEST.ALERT_START_LINE' | translate"></div>
          <div>
            <span
              [innerHTML]="
                'WATCH_REQUEST.ALERT_BASE_PRICE' | translate: { price: price$ | async | number }
              "
            ></span>
          </div>
          <div [innerHTML]="'WATCH_REQUEST.ALERT_END_LINE' | translate"></div>
        </ng-template>
      </nz-alert>
    </section>
    <section>
      <textarea
        nz-input
        [(ngModel)]="comment"
        [rows]="4"
        [placeholder]="'WATCH_REQUEST.COMMENT' | translate"
      ></textarea>
    </section>
  </ng-template>
</nz-skeleton>

<ng-container *nzModalFooter>
  <button
    nz-button
    nzType="primary"
    [disabled]="!comment"
    [nzLoading]="creating$ | async"
    (click)="createRequest()"
  >
    {{ 'WATCH_REQUEST.SEND' | translate }}
  </button>
  <button nz-button (click)="close()">
    {{ 'CANCEL' | translate }}
  </button>
</ng-container>
