import { MutationHandlingServiceV2, QueryHandlingService } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {
  Lot,
  LotSource,
  LotTypeEnum,
} from 'src/app/modules/graphql/service/graphql-auto-main-service';
import {
  LotWatchRequestDataGQL,
  RequestPhotoFromAuctionGQL,
  VideoWatchTypeEnum,
} from 'src/app/modules/graphql/service/graphql-shared-familiar-service';
import { LotShareService } from 'src/app/services/lot-share.service';
import { FilesByType } from '../../types/lot-watch-request-form-files.type';

@Component({
  selector: 'app-lot-watch-request-form-modal',
  templateUrl: './lot-watch-request-form-modal.component.html',
  styleUrls: ['./lot-watch-request-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotWatchRequestFormModalComponent implements OnInit, OnDestroy {
  @Input() lot: Lot;
  @Input() lotType: LotTypeEnum;
  @Input() videoWatchType: VideoWatchTypeEnum;
  @Input() lotSource: LotSource;
  @Input() lotShared: EventEmitter<void>;

  loading$: Observable<boolean>;
  creating$ = of(false);

  lotLink$: Observable<string>;
  price$: Observable<number>;
  alreadyCreated$: Observable<boolean>;
  filesByType$: Observable<FilesByType>;
  hasFiles$: Observable<boolean>;

  comment = '';

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly modal: NzModalRef,
    private readonly qhs: QueryHandlingService,
    private readonly mhs: MutationHandlingServiceV2,
    private readonly lotShareService: LotShareService,
    private readonly lotWatchRequestDataGQL: LotWatchRequestDataGQL,
    private readonly requestPhotoFromAuctionGQL: RequestPhotoFromAuctionGQL,
  ) {}

  private isVideoFile(url: string): boolean {
    return url.toLowerCase().match(/\.(mp4|webm|ogg)$/) !== null;
  }

  private separateFilesByType(files: string[]): FilesByType {
    return files.reduce(
      (acc, file) => {
        if (this.isVideoFile(file)) {
          acc.videos.push(file);
        } else {
          acc.images.push(file);
        }
        return acc;
      },
      { images: [], videos: [] } as FilesByType,
    );
  }

  ngOnInit(): void {
    const shareLotResult = this.lotShareService.share(this.lot, this.lotType, this.lotSource);

    this.lotLink$ = shareLotResult.link$.pipe(shareReplay(1));
    const dataQueryRef$ = this.lotLink$.pipe(
      map((lotLink) =>
        this.qhs.fetch(
          this.lotWatchRequestDataGQL,
          { type: this.videoWatchType, lotLink },
          'network-only',
        ),
      ),
      shareReplay(1),
    );

    const data$ = dataQueryRef$.pipe(switchMap(({ data }) => data));
    this.price$ = data$.pipe(map((data) => data.serviceRequestPrice || 0));
    const serviceRequest$ = data$.pipe(map((data) => data.serviceRequestAuctionLotByUser));
    this.alreadyCreated$ = serviceRequest$.pipe(map((request) => !!request));

    const files$ = serviceRequest$.pipe(
      map((request) => request.files || []),
      map((files) => files.map((file) => file.fullPathForFile)),
    );

    this.filesByType$ = files$.pipe(
      map((files) => this.separateFilesByType(files)),
      shareReplay(1),
    );

    this.hasFiles$ = this.filesByType$.pipe(
      map(({ images, videos }) => images.length > 0 || videos.length > 0),
    );

    this.loading$ = combineLatest([
      shareLotResult.loading$,
      dataQueryRef$.pipe(
        switchMap(({ loading }) => loading),
        startWith(true),
      ),
    ]).pipe(
      map((loadings) => loadings.some((loading) => loading)),
      distinctUntilChanged(),
      shareReplay(1),
    );

    if (!this.lotShareService.getLink(this.lot)) {
      this.lotLink$.pipe(first(), takeUntil(this.destroy$)).subscribe(() => this.lotShared.emit());
    }
  }

  async createRequest(): Promise<void> {
    const lotLink = await this.lotLink$.toPromise();
    const mutationRef = this.mhs.mutate(this.requestPhotoFromAuctionGQL, {
      input: {
        lotLink,
        type: this.videoWatchType,
        lotData: `${this.lot.code} - ${this.lot.auctionName}`,
        comment: this.comment,
        dueDate: this.lot.auctionDatetime,
      },
    });

    this.creating$ = mutationRef.loading;
    mutationRef.data.pipe(first(), takeUntil(this.destroy$)).subscribe(() => this.close());
  }

  close(): void {
    this.modal.destroy();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
