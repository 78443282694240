import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import {
  LotSource,
  LotTypeEnum,
  UserLotMark,
} from '../../graphql/service/graphql-auto-main-service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AutoLotView } from '../../../const';
import { LotAddSharedListModalComponent } from './components/lot-add-shared-list-modal/lot-add-shared-list-modal.component';

@Component({
  selector: 'app-lot-add-shared-list-modal-button',
  templateUrl: './lot-add-shared-list-modal-button.component.html',
  styleUrls: ['./lot-add-shared-list-modal-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotAddSharedListModalButtonComponent {
  @Input() lot: AutoLotView;
  @Input() lotType: LotTypeEnum;
  @Input() lotSource: LotSource;
  @Input() userLotMarks: UserLotMark[];
  @Input() fullSize = false;
  @Input() isUpdate = false;
  @Output() actionCompleted = new EventEmitter<void>();

  constructor(
    private readonly translate: TranslateService,
    private readonly modalService: NzModalService,
  ) {}

  openLotAddListButtonComponent(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('SHARED.SELECT_LIST'),
      nzContent: LotAddSharedListModalComponent,
      nzWidth: this.fullSize ? '80%' : '40%',
      nzComponentParams: {
        lot: this.lot,
        lotType: this.lotType,
        lotSource: this.lotSource,
        userLotMarks: this.userLotMarks,
      },
      nzFooter: null,
    });
  }
}
