import { ru } from './ru';

export const mn: typeof ru = {
  EDIT: 'Засах',
  UPDATE: 'Шинэчлэх',
  LANGUAGES: 'Хэл',
  RU: 'Орос',
  EN: 'Англи',
  ANY: 'Дурын',
  ANY_M: 'Дурын',
  ERROR: 'Алдаа',
  WARNING: 'Анхааруулга',
  ATTENTION: 'Анхаар',
  SHOW: 'Харуулах',
  SHOW_EXTENDED_PERIOD: 'Удаан харуулах',
  DELETE: 'Устгах',
  SELECTIONS: 'Сонгосон',
  BOUNDARY_LOTS: 'Хүрээ',
  TOTAL_BOUNDARY: 'Нийт хүрээ: {{count}}',
  ON_DATE_BOUNDARY: '{{date}} өдрийн хүрээ: {{count}}',
  ARE_YOU_SURE: 'Итгэлтэй байна уу?',
  ARE_YOU_SURE_DELETE: 'Устгахдаа итгэлтэй байна уу?',
  INFORMATION: 'Лавлах мэдээлэл',
  TRANSLATION: 'Орчуулга',
  AUTO: 'Авто',
  MOTO: 'Мото',
  SELECT_AUCTION: 'Аукшн сонгох',
  COMPANY_MODEL: 'Үйлдвэр, загвар',
  SELECT_COMPANY: 'Компани сонгох',
  COMPANY: 'Компани',
  SELECT_MODEL: 'Загвар сонгох',
  MODEL: 'Загвар',
  ENGINE_VOLUME: 'Хөдөлгүүрийн багтаамж',
  SEE_ALL: 'Бүгдийг харах',
  PLEASE_SELECT: 'Сонгоно уу',
  SEARCH: 'Шүүх',
  CLEAR: 'Арилгах',
  PHOTO: 'Фото',
  LOT: 'Лот',
  GREEN_CORNER: 'Green corner',
  NOTIFY: 'Notify about the results of the auction',
  TRANSLATE: 'Translation requested',
  NO_LIST: 'Жагсаалт байхгүй',
  LOT_MANUALLY: 'Лот гараар',
  FILTER_DATA_OUTDATED: 'Шүүх дата хуучирсан. Шүүлтийг шинэчилнэ үү',
  FILTERS: 'Шүүлтүүрүүд',
  DATE: 'Он сар',
  EQUIPMENT: 'Хэрэгсэл',
  SELECT_LOT_TYPE: 'Лот төрөл сонгох',
  RATE_MUST_BE_HIGHER: 'Эхлэх үнэтэй тэнцүү эсвэл их байх ёстой',
  WRONG_YEAR: 'Он буруу',
  WRONG_BID_CONDITION: 'Үнийн нөхцөл буруу',
  TOTAL: 'Нийт',
  SIGN_OUT: 'Гарах',
  AUCTION_NOT_STARTED: 'Аукшн эхлээгүй байна',
  LOT_NOT_FOUND: 'Лот олдсонгүй',
  ONE_MORE_CHANCE: 'Ганц боломж',
  BID_PLACED: 'Үнэ бүртгэгдлээ!',
  BID_MAY_NOT_BID_PROCESSED: '18:00 цагаас хойш таны өгсөн үнэ хүчингүй болж болно.',
  BID_DELETE_PROHIBITED_1700: 'Аукшны өдрийн 17:00 цагаас хойш үнэ устгах хүсэлт гаргах боломжгүй.',
  BID_DELETE_PROHIBITED_1100:
    'Бямба гарагийн 11:00 цагаас хойш үнэ устгах хүсэлт гаргах боломжгүй.',
  SOURCE: 'Эх сурвалж',
  TRANSLATE_LOT: 'Лот орчуулах',
  PREVIOUS_SALES: 'Борлуулалтын статистик',
  GUIDANCE_REQUEST_CREATED: 'Лот, бидний мэдээлэл харах хүсэлт гаргалаа',
  STATISTICS: 'Statistics',
  MUTATION_FAILED: 'Амжилтгүй',
  MUTATION_SUCCEEDED: 'Амжилттай',
  ENABLE_NEW_LOTS_NOTIFICATION: 'Шинэ лотны талаарх мэдэгдэл асаах',
  DISABLE_NEW_LOTS_NOTIFICATION: 'Шинэ лотны талаарх мэдэгдэл унтраах',
  AUCTION_REMOTENESS: {
    LOW: 'Аукшны алс байдал - бага',
    MEDIUM: 'Аукшны алс байдал - дунд',
    HIGH: 'Аукшны алс байдал - их',
  },
  FRAME_MODEL: 'Кузов',
  STATUS: 'Статус',
  ARTICLES: 'Артиклууд',
  TRANSMISSION: 'Кроп',
  HOME: 'Нүүр хуудас',
  SEARCH_BY_BID: 'Бид дугаараар хайх',
  INPUT_BID_NUMBER: 'Бид дугаар оруулах',
  BID_NUMBER: 'Бид дугаар',
  IN_CURRENCY: 'Сонгосон валютаар: {{amount}}',
  AMOUNT: 'Дүн',
  GROUP: 'Бүлэг',
  LOT_DATA: 'Лот мэдээлэл',
  BIDS_DATE: 'Бид хугацаа',
  MY_BIDS: 'Миний бид',
  BID_STATUS: 'Статус',
  CONDITION: 'Экспорт төрөл',
  Wed: 'Лхагва',
  Thu: 'Пүрэв',
  Fri: 'Баасан',
  Sat: 'Бямба',
  Sun: 'Ням',
  Mon: 'Даваа',
  Tue: 'Мягмар',
  FROM: 'Хаанаас',
  TO: 'Хаашаа',
  SELECT_GROUP: 'Сонгох',
  FRAME_NUMBER: 'Кузов дугаар',
  DATE_MANUFACURE: 'Үйлдвэрлэсэн он сар',
  CLOSE: 'Хаах',
  CHECK: 'Шалгах',
  CANCEL: 'Цуцлах',
  CREATE: 'Нээх',
  SAVE: 'Хадгалах',
  SAVED: 'Хадгалсан',
  CHECK_DATE_OF_MANUFACTURE: 'Үйлдвэрлэсэн он сар шалгах',
  DATE_MANUFACURE_NOT_FOUNDED: 'Тухайн дугаарт үйлдвэрлэсэн он сар олдсонгүй',
  UNDERSTANDING_THE_JAPANESE_AUCTION_SHEET: 'Япон аукшны хуудсыг ойлгох нь',
  SMALL_SCRATCH: 'Жижиг зураас',
  SCRATCH: 'Зураас',
  BIG_SCRATCH: 'Том зураас',
  FEW_DIMPLES: 'Цөөн хонхорхой',
  SEVERAL_DIMPLES: 'Хэдэн хонхорхой',
  MANY_DIMPLES: 'Олон хонхорхой',
  SMALL_DENT: 'Жижиг хонхорхой',
  DENT: 'Хонхорхой',
  BIG_DENT: 'Том хонхорхой',
  REPAIR_MARK_WAVE_HARDLY_DETECTABLE: 'Зассан ул мөр/Долгио (үл мэдэг)',
  REPAIR_MARK_WAVE: 'Зассан ул мөр/Долгио',
  OBVIOUS_REPAIR_MARK_WAVE_NEEDS_TO_BE_REPAINTED:
    'Мэдэгдэхүйц зассан ул мөр/Долгио (дахиж засах шаардлагатай)',
  RUST: 'Зэв',
  HEAVY_RUST: 'Их зэв',
  CORROSION: 'Исэлдэлт',
  HEAVY_CORROSION: 'Их исэлдэлт',
  PAINT_MARKED: 'Будаг зөрсөн',
  PAINT_FADED_X_NEED_TO_BE_REPLACED: 'Будаг арилсан Х Солих шаардлагатай',
  REPLACED: 'Сольсон',
  SMALL_DENT_WITH_SCRATCH_SIZE_LIKE_A_THUMB: 'Жижиг зурагдсан хонхорхой (эрхий хуруу шиг)',
  DENT_WITH_SCRATCH_SIZE_LIKE_FLAT_OF_THE_HAND: 'Зурагдсан хонхорхой (алганы хэмжээтэй)',
  BIG_DENT_WITH_SCRATCH_SIZE_LIKE_ELBOW: 'Том зурагдсан хонхорхой (тохойны хэмжээтэй)',
  SMALL_HOLE_OR_CRACK: 'Жижиг нүх эсвэл хагархай',
  HOLE_OR_CRACK: 'Нүх эсвэл хагархай',
  BIG_HOLE_OR_CRACK: 'Том нүх эсвэл хагархай',
  SMALL_CRACK_ON_WINDSHIELD_APPROXIMATELY_1CM: 'Салхины шилны цууралт (ойролцоогоор 1см)',
  REPAIRED_CRACK_ON_WINDSHIELD: 'Салхины шилны цууралт зассан',
  REPAIRED_CRACK_ON_WINDSHIELD_NEEDS_TO_BE_REPLACED:
    'Салхины шилны цууралт зассан (солих шаардлагатай)',
  CRACK_ON_WINDSHIELD_NEEDS_TO_BE_REPLACED: 'Салхины шилны цууралт (солих шаардлагатай)',
  STONE_CHIP_IN_GLASS: 'Салхины шилэнд чулууны сэвтэй',
  DATE_OF_MANUFACTURE_HINT:
    'Анхаар! Үйлдвэрлэсэн он сарыг гуравдагч эх сурвалжаас хардаг тул үнэн зөв эсэхийг манайх хариуцахгүй.',
  DATE_OF_MANUFACTURE_WARNING:
    'Арлын дугаарыг албаар буруу оруулбал үйлдвэрлэсэн он сар шалгах эрхгүй болох болно.',
  EXTRA_DESCRIPTION: 'Нэмэлт тайлбар',
  INITIAL: 'Хүлээгдэж буй',
  CONFIRMED: 'Баталгаажсан',
  CANCELED: 'Цуцласан',
  WON: 'Ялсан',
  TRANSLATED: 'Орчуулсан',
  ALL: 'Бүгд',
  LEFT_DRIVE: 'Зүүн',
  RIGHT_DRIVE: 'Баруун',
  PRODUCTION_DATE: 'Үйлдвэрлэсэн он сар',
  CREATE_PRODUCTION_DATE_CHECK_REQUEST: 'Үйлдвэрлэсэн он сар хүсэх',
  CREATED_AT: '-нд Нээгдсэн',
  TODAY: 'Өнөөдөр',
  ADD_PHOTO: 'Зураг нэмэх',
  GET_PHOTO: 'Зураг татах',
  GET_PHOTO_REQUEST_HINT: '2-10 мин хүлээнэ үү',
  PLEASE_SET_PASSWORD_0: 'Данстай холбоотой Имэйл, нэвтрэх нэр эсвэл утасны дугаар ашиглана уу',
  REGISTRATION: 'Данс үүсгэх',
  FOR_AUTH_USE_AKEOBONO_WORLD_ACCOUNT: 'Akebono.World дансаар зөвшөөрнө үү',
  PLEASE_SET_PASSWORD_1: 'Нууц үг шинэчлэх',
  PLEASE_SET_PASSWORD_2: 'Хэрэв та анхны удаа нэвтэрч байгаа бол',
  VISITED: 'Зочилсон',
  FORBIDDEN_TITLE: 'Лот харах эрх байхгүй',
  FORBIDDEN_MESSAGE: 'Уучлаарай, танд эрх байхгүй байна. Аукшны менежертэй холбогдоно уу',
  SEND_REQUEST_TO_ACCESS: 'Хандах эрх хүсэх',
  SEND: 'Илгээх',
  LAST_NAME: 'Овог',
  INPUT_ERROR_LAST_NAME: 'Овог шаардлагатай',
  INPUT_LAST_NAME: 'Овогоо оруулна уу',
  FIRST_NAME: 'Нэр',
  INPUT_ERROR_FIRST_NAME: 'Нэр шаардлагатай',
  INPUT_FIRST_NAME: 'Нэрээ оруулна уу',
  MIDDLE_NAME: 'Дунд нэр',
  INPUT_ERROR_MIDDLE_NAME: 'Дунд нэр шаардлагатай',
  INPUT_MIDDLE_NAME: 'Дунд нэрээ оруулна уу',
  SEND_REQUEST_TO_ACCESS_SUCCESS: 'Хүсэлт амжилттай илгээгдлээ',
  GUIDANCE_REQUEST_ALREADY_EXISTS: 'Лот харах эрхийн хүсэлт илгээгдлээ.',
  REQUEST_TYPE_NOT_SUPPORTED: 'Хүсэлтийн төрөл дэмжигдээгүй',
  Success: 'Амжилттай',
  'Bid placed!': 'Бид хийгдсэн!',
  'Please fill all required fields': 'Шаардлагатай талбаруудыг бөглөнө үү',
  'Photo request sent!': 'Зургийн хүсэлт илгээгдсэн!',
  'Sign in': 'Нэвтрэх',
  'Send new password': 'Шинэ нууц үг илгээх',
  Auto: 'Авто',
  Manual: 'Мануал лот',
  Lot: 'Лот',
  'Moto lots': 'Мото',
  'Moto lot': 'Лот',
  NOTE: 'Note',
  SELLING_STATISTICS: 'Previous auctions',
  SELECT: 'Сонгох',
  OLD_INTERFACE: 'Өмнөх хувилбар',
  ONEPRICE: 'Тогтсон үнэтэй',
  FREE_SERVICE: 'Үнэгүй үйлчилгээ',
  MODEL_GRADE: 'Загварын зэрэг',
  DAYS_TO_SHIP: 'Худалдан авсанаас Орос хүрэх дундаж хугацаа',
  AVERAGE_TIME_FROM_BUY_TO_SHIP: '{{auctionName}}-с онгоцонд ачигдах дундаж хугацаа',
  DAYS: 'Хоногууд',
  Автомашина: 'Автомашин',
  'Мото техника': 'Мотоцикл',
  SCORE: {
    TITLE: 'Бид үнэлгээ',
    RATE_0: 'Боломжгүй',
    RATE_5: 'Худалдаж авах магадлал Маш бага',
    RATE_10: 'Худалдаж авах магадлал Маш бага',
    RATE_15: 'Худалдаж авах магадлал Маш бага',
    RATE_20: 'Худалдаж авах магадлал Маш бага',
    RATE_25: 'Худалдаж авах магадлал Маш бага',
    RATE_30: 'Худалдаж авах магадлал бага',
    RATE_35: 'Худалдаж авах магадлал бага',
    RATE_40: 'Худалдаж авах магадлал бага',
    RATE_45: 'Худалдаж авах магадлал дунд',
    RATE_50: 'Худалдаж авах магадлал дунд',
    RATE_55: 'Худалдаж авах магадлал дунд',
    RATE_60: 'Худалдаж авах магадлал дунд',
    RATE_65: 'Худалдаж авах магадлал өндөр',
    RATE_70: 'Худалдаж авах магадлал өндөр',
    RATE_75: 'Худалдаж авах магадлал өндөр',
    RATE_80: 'Худалдаж авах магадлал өндөр',
    RATE_85: 'Худалдаж авах магадлал өндөр',
    RATE_90: 'Худалдаж авах магадлал маш өндөр',
    RATE_95: 'Худалдаж авах магадлал маш өндөр',
    RATE_100: 'Худалдаж авах магадлал маш өндөр',
  },
  DRIVER_CONFIG: {
    PREV_BTN: 'Өмнөх',
    NEXT_BTN: 'Дараах',
    DONE_BTN: 'Болсон',
  },
  AUTO_LOT: {
    GUIDE: {
      TITLE_BLOCK: {
        TITLE: 'Багцын гарчиг',
        DESCR:
          'Багцын ерөнхий мэдээлэл. Үйлдвэрлэгч, загвар, гүйлт, дуудлага худалдааны газар ба түүний он сар',
      },
      PHOTO_GALLERY: {
        TITLE: 'Фото галлерей',
        DESCR:
          'Багцын зургууд. Зүүн доод буланд дуудлага худалдааны хуудсын заавар байгаа. Зураг байхгүй бол "Зураг хүсэх" товч дээр дарах эсвэл менежерээсээ лавлана уу',
      },
      ADDITIONAL_TAGS: {
        TITLE: 'Багцын нэмэлт тэмдэглэгээ',
        DESCR: 'Чухал давуу эсвэл сул талын тухай багцын нэмэлт тэмдэглэгээ',
      },
      ACTION_BTNS: {
        FIRST: {
          TITLE: 'Action buttons',
          DESCR:
            'You can open the bidding window, copy the link to current lot, request photos or translation and etc. Also, here you can access sales statistics for similar models and instructions for reading some of the symbols on auction sheets',
        },
        SECOND: {
          TITLE: 'Үйлдлийн товчнууд',
          DESCR:
            'Үнийн саналын цонхыг нээх, тухайн багцын холбоосыг хуулах, зураг, орчуулга хүсэх гэх мэтийг хийж болно. Мөн эндээс та ойролцоо загваруудын борлуулалтын статистикийг харж болохоос гадна дуудлага худалдааны хуудас дээрх зарим тэмдэглэгээний тухай зааврыг харж болно.',
        },
      },
      SELECTION: {
        TITLE: 'Сонголтууд',
        DESCR:
          'Сонголтын жагсаалтад дуртай багцаа нэмснээр дараа нь дахин олохын тулд маш их цаг зарцуулах шаардлагагүйгээр үзэх боломжтой болно. Таны бүх сонголтууд вэб сайтын дээд хэсэгт байрлах Сонголтууд дээр харагдах болно',
      },
      DATE_OF_MANUFACTURE: {
        FIRST: {
          TITLE: 'Үйлдвэрлэгдсэн огноо',
          DESCR:
            'Дуудлага худалдааны хуудаснаас явах эд ангиудын дугаарын дутуу хэсгийг оруулж, машины яг үйлдвэрлэсэн огноог асуу. Хэрэв манай мэдээллийн санд байгаа бол огноог шууд авах боломжтой, эс тэгвээс та манай менежерт үйлдвэрлэлийн огноог тодруулах хүсэлт илгээж болно.',
        },
        SECOND: {
          TITLE: 'Үйлдвэрлэгдсэн огноо',
          DESCR:
            'Үйлдвэрлэлийн огнооны хүсэлтийн талаар та манайхаас илүү ихийг олж мэдэх боломжтой',
          LINK: 'F.A.Q. хэсэг',
        },
      },
      LOT_DATA: {
        TITLE: 'Тээврийн хэрэгслийн өгөгдөл',
        DESCR: 'Нэр, жил, өнгө, явсан гүйлт гэх мэт.',
      },
      AUCTION_DATA: {
        TITLE: 'Дуудлага худалдааны мэдээлэл',
        DESCR:
          'Нэр, багцын дугаар, дуудлага худалдааны огноо. Мөн та дуудлага худалдааны тоймтой танилцаж, өөрийн сэтгэгдэлээ үлдээх боломжтой',
      },
      BIDS: {
        FIRST: {
          TITLE: 'Үнийн санал',
          DESCR:
            'Санхүүгийн гол үзүүлэлтүүдийг энд харуулав. Эхлэх үнэ, таны саналын хэмжээ, бусад оролцогчдын санал байгаа эсэх. Хэлэлцээрт нээлттэй байгаа тохиолдолд та хэлэлцээрт оролцохоо баталгаажуулах шаардлагатай. Баталгаажуулах товчлуур нь ижил блокт байх болно',
        },
        SECOND: {
          TITLE: 'Үнийн санал',
          DESCR:
            'Орчуулга хүлээн авсны дараа та орчуулгын хүсэлтээр санал тавьсан бол дуудлага худалдаанд оролцохоо баталгаажуулах шаардлагатай.',
        },
      },
      SELL_STATS: {
        TITLE: 'Борлуулалтын статистик',
        DESCR:
          'Эндээс та манай мэдээлэлд үндэслэн ижил төстэй тээврийн хэрэгслийн борлуулалтын статистик мэдээллийг авах боломжтой',
      },
      ADDITIONAL_DESCR: {
        TITLE: 'Нэмэлт тэмдэглэл',
        DESCR:
          'Эндээс та дуудлага худалдаанаас хүлээн авсан багцын талаарх нэмэлт мэдээллийг авах боломжтой',
      },
      CALC: {
        TITLE: 'Зардлын тооцоолуур',
        DESCR: 'Тооцоологч нь тооцоолсон зардлыг урьдчилан таамаглахад тусална',
      },
    },
  },
  LOT_BIDDING_MODAL: {
    HIGHT_IMPORT_DUTY:
      'High recycling fee upon import into Russia. Consult with a customs broker before purchasing.',
    MAX_BID_AMOUNT_LIMIT: 'Бид хийх дээд боломж: {{amount}}¥',
    BID_MUST_BE_ROUND: '5000Тай тэгш дүнгээр бид хийнэ үү',
    DAILY_LIMIT_REACHED: '{{Date}} өдрийн бид хийх хязгаарт хүрсэн',
    LOT_LIMIT_REACHED: 'Энэ лотын бид хийх хязгаарт хүрсэн',
    TENDER_AUCTION_TIME_LIMIT_REACHED: 'Тендер аукшнууд 13:30 хүртэл бид хүлээж авна',
    FILL_ALL_FIELDS: 'Бүх шаардлагатай талбарыг бөглөнө үү!',
    FAIL_CREATE_GROUP: 'Групп үүсгэхэд алдаа гарлаа',
    SUCCESS_CREATE_GROUP: 'Групп үүссэн',
    FAIL_CREATE_BID: 'Бид амжилтгүй',
    SUCCESS_CREATE_BID: 'Бид хийгдсэн',
    PLACE_YOUR_BID: 'Бид хийх',
    AMOUND_SHOULD_HINT: 'Дүн нь {{amount}} тэнцүү эсвэл их байх ёстой',
    AMOUND_SHOULD_VALIDATION: 'Хамгийн бага бид өсгөх давтамж {{amount}}',
    YOUR_BID: 'Таны бид',
    NO_GROUP: 'Групп байхгүй',
    NEW_GROUP: 'Шинэ групп',
    QUANTITY: 'Тоо ширхэг',
    TENDER_AUCTION_NO_GROUP: 'Тендер аукшн биднүүд группт орохгүй',
    SELECT_CONDITIONS: 'Нөхцөл сонгох',
    YOUR_COMMENT: 'Таны сэтгэгдэл',
    TRANSLATE_REQUEST: 'Аукшн лист орчуулах хүсэлт',
    NOTIFY_REQUEST: 'Аукшны үр дүнг надад мэдээллэх',
    NOTIFY_REQUEST_TOOLTIP:
      'Таны үнийн санал ялаагүй ч гэсэн танд тус багцын дуудлага худалдааны үр дүнгийн тухай мэдэгдэл очих болно',
    AGREEMENT_HINT1: 'Аукшн лотыг миний бие өөрөө сонгосон. Би зөвшөөрч байна.',
    AGREEMENT_HINT2: 'Танилцсан',
    BID_RULES: 'Бид хийх дүрэм',
    IN_ACCORDANCE_WITH: '-ын дагуу',
    NAVIS_RULES_HINT1: 'Бид хийснээр би ... уншиж зөвшөөрч байгаагаа батлаж байна.',
    NAVIS_RULES_HINT2: 'Задаргааны журам',
    NAVIS_RULES_TITLE: 'Задаргааны дүрэм',
    LIMIT: 'Хязгаар',
    DOESNT_MAKE_SENSE: 'Бидийн ялах магадлал хэтэрхий бага байна. Та итгэлтэй байна уу?',
    NEED_PRIORITY_PASS: 'Онцгой тээвэр захиалах',
    PRIORITY_PASS: 'Онцгой тээвэр',
    GREEN_CORNER: '"Миний талбай" горим',
    GREEN_CORNER_WARNING: 'Энэ горимыг хуучирсан тээврийн хэрэгсэлд ашиглах боломжгүй',
    DEFAULT_DELIVERY: 'Стандарт хүргэлт',
    CAN_PACE_ONLY_ONE_BID:
      'Дуудлага худалдаа дуусахад 60 хүрэхгүй минут үлдлээ. Та дахин өсгөх боломжгүйгээр нэг бид хийх боломжтой.',
    IS_SANCTIONED_UTILIZATION_SURE:
      'Бидийн "Дахин боловсруулах" нөхцөлийг сонгосон байна. Та итгэлтэй байна уу?',
    WANT_PLACE_BID: 'Та бид хиймээр байна уу?',
    CANT_CREATE_TRANSLATION:
      'Дуудлага худалдаа эхлэхэд нэг цаг хүрэхгүй хугацаа үлдсэн тохиолдолд орчуулгын хүсэлтийг хүлээн авах боломжгүй',
    PRIORITY_PASS_PARAGRAPHS: {
      '1': '3-4 недели ожидания от покупки на кнопке до получения во Владивостоке*',
      '2': 'Автомобиль и документы будут максимально быстро доставлены с аукциона, отправка первым же возможным рейсом в РФ (при наличии экспортного сертификата) и возможность забрать автомобиль с готовым ЭПТС без очереди на СВХ на следующий день после выпуска авто от офиса компании Прим-Брокер**.',
      '3': 'Стоимость услуги: 500$ + 50000 йен + 15000₽, оплата услуги в России при получении машины.',
      '4': 'Заказать данную услугу можно также в Личном Кабинете из карточки объекта в течении суток после выигрыша лота.',
      '5': 'Заказать данную услугу можно также в Личном Кабинете из карточки объекта в течении суток после выигрыша лота.',
      NOTES: {
        '1': '*За исключением случаев, когда сроки доставки не могут быть сокращены по независящим от нас причинам, связанным с введением санкций, с судоходством, плохими погодными условиями, с задержкой документов на транспортное средство со стороны аукциона.',
        '2': '**Услуга прохождения испытательной лаборатории, которая входит в экспресс доставку (Priority Pass), осуществляется только при соответствии автомобиля техническому регламенту. При наличии: рейлингов, фаркопа, нестандартного освещения, размера колёс и прочего нестандартного оснащения автомобиля, может последовать отказ в прохождении испытаний от лаборатории. В этом случае возможно увеличение сроков прохождения испытаний и стоимости услуги.',
      },
    },
    DISABLED_REASONS: {
      'data-changes': 'Лот дата өөрчлөгдсөн',
      'moto-overprice': 'Лот аукшнаас буцаагдсан',
      'time-over': 'Цаг дууссан учир дахин бид хийх боломжгүй',
      'bids-limit': 'Лотын бид хийх хязгаарт хүрсэн',
      'lots-limit': 'Таны бид хийх лотын тоо хязгаартаа хүрсэн',
      'chance-used': 'Сүүлийн боломж бид хийгдсэн байна',
    },
    GUIDE: {
      BID_AMOUNT: {
        TITLE: 'Бид хийх дүн',
        DESCR: 'Бид хийх хэмжээг оруулна. Тухайн дүнд таны ялах боломжыг систем харуулах болно.',
      },
      BIDS_GROUPING: {
        ZERO: {
          TITLE: 'Үйлдэл',
          DESCR: '"Шинэ групп" товчин дээр дарна уу',
        },
        FIRST: {
          TITLE: 'Бидүүдийг групплэх',
          DESCR:
            'Бидүүдээ хамтад нь групплэж машин худалдаж авах боломжоо ихэсгээрэй. Жишээ нь: та 5н машин дээр бид хийж байгаа ч зөвхөн 2-ийг нь худалдаж авах хэрэгтэй үед. Дурын үсэг болон 2-ын тоотой групп үүсгээрэй. Өөрийн биднүүдээ тус группд нэмээрэй. Ингэснээр бид таны тухайн групп доторх зөвхөн 2 машиныг авах хэрэгтэй гэдгийг ойлгох болно.',
        },
        SECOND: {
          TITLE: 'Бидүүдийг групплэх',
          DESCR:
            'Групп дэх бүх лотууд адил аукшн өдөртэй байх ёстой. Үгүй бол та илүү машин худалдаж авах аюултай. Дор хаяж нэг групп үүссэний дараа товчны зүүн талын шинэ талбарт та лотуудыг сонгох боломжтой болно.',
        },
        CLOSE: {
          TITLE: 'Арилгах үйлдэл',
          DESCR: 'Энд дарж группыг устгана уу',
        },
      },
      CALC: {
        TITLE: 'Тооцоолуур',
        DESCR: 'Тооцоолуур танд тухайн лотын Япон дахь тээврийн ойролцоо өртөгийг харуулах болно',
      },
      CONDITION: {
        TITLE: 'Нөхцөл байдал',
        DESCR: 'Тээврийн хэрэгслийг очих боомтод хүргэх төрлийг сонгоно уу',
      },
      COMMENT: {
        TITLE: 'Сэтгэгдэл',
        DESCR:
          'Лот дахь ямар нэгэн чухал зүйлийн тухай бидэнд мэдэгдэхийг хүсвэл сэтгэгдэл үлдээгээрэй',
      },
      LOT_TRANS: {
        FIRST: {
          TITLE: 'Лотын орчуулга 1',
          DESCR:
            'Лотын орчуулгын үйлчилгээг ашиглан обьектын тухай мэдээллийг тодруулаарай. Профайл тохиргоо хэсэгт орчуулах хэлийг сонгоно уу.',
        },
        SECOND: {
          TITLE: 'Лотын орчуулга 2',
          DESCR:
            'Шилжүүлэг ирсэн даруйд та түүнтэй танилцаж, дуудлага худалдаанд оролцохоо баталгаажуулах шаардлагатай. Үгүй бол таны бооцоо хүчингүй болно',
        },
      },
      NOTIFY_RES: {
        TITLE: 'Үр дүнгийн мэдэгдэл',
        DESCR:
          'Хэрэв та арилжааны үр дүнгийн талаар мэдэгдэл хүлээн авахыг хүсвэл энэ зүйлийг идэвхжүүлнэ үү. Хэрэв та тохиргооноос үүнийг идэвхжүүлсэн бол мэдэгдлийг танд имэйл болон Telegram \\notif илгээх болно',
      },
      BIDS_RULES: {
        TITLE: 'Үнэ хаялцах дүрэм',
        DESCR:
          'Дуудлага худалдаанд оролцохын тулд та саналаа байршуулах, боловсруулах нөхцөлтэй танилцаж, хүлээн авах ёстой',
      },
      GREEN_CORNER: {
        TITLE: '"Ногоон булан" горим',
        DESCR:
          'Хэрэв та "Ногоон булан" горимоор тээврийн хэрэгсэл худалдаж авахыг хүсвэл үүнийг идэвхжүүлнэ үү. Дэлгэрэнгүй мэдээллийг менежертэй холбогдож асууна уу',
      },
      FINISH: {
        TITLE: 'Мэдээлэл дууслаа',
        DESCR: 'Анхаарал хандуулсанд баярлалаа!',
      },
    },
  },
  ARTICLE: {
    ARTICLE_NOT_FOUND: 'Нийтлэл олдсонгүй',
    TRANSLATE_NOT_FOUND: 'Орчуулга олдсонгүй',
    CATEGORIES: 'Ангилал',
    OPEN_CATEGORY_MENU: 'Ангиллын цэсийг нээх',
    ARTICLE_LOAD_ERROR: 'Артикл ачааллахад алдаа гарлаа',
    CATEGORIES_LOAD_ERROR: 'Категор ачааллахад алдаа гарлаа',
  },
  USER_REQUESTS: {
    LANGUAGE: 'Хэл',
    CREATE: 'Хүсэлт илгээх',
    CREATE_SUCCESS: 'Амжилттай хүсэлт илгээлээ',
    CREATE_FAIL: 'Хүсэлт илгээхэд алдаа гарлаа',
    MESSAGE: 'Захиа',
    TRANSLATION_MEDIA_TYPE: 'Орчуулгын төрөл',
    ALERT:
      'Эхлээд ирсэн лотийг түрүүлж орчуулна. Дуудлага худалдааны хамгийн ойр огноотой лот-д давуу эрх олгоно',
    SATURDAY_TIME_LIMIT_ALERT:
      'Бямба гарагийн орчуулгын хүсэлтийг Японы цагаар 11:00 цаг хүртэл хүлээн авна',
    LOT_TRANSLATE_REQUEST: 'Орчуулгын хүсэлт илгээх',
    BID_DELETE_REQUEST: 'Бид устгах хүсэлт илгээх',
    BID_PLACE_COMMENT: 'Сэтгэгдэл бичих',
    BID_DELETE_WARNING:
      'Бид устгах хүсэлт нь бид бодитоор цуцалсан гэсэн үг биш бөгөөд зарим тохиолдолд бид цуцлах боломжгүй юм. Токиогийн цагаар ажлын өдрүүдэд 17:00, бямба гаригийн 11:00 цагаас хойш бид устгах боломжгүй.',
    BID_DELETE_WARNING2: 'Анхааруулга:',
    BID_DELETE_WARNING3:
      'Хэрэв та энэ лот-ын бүх бид устгахыг хүсвэл өөрийн хийсэн бид болгонд хүсэлт гаргах шаардлагатай',
  },
  UPLOAD_PHOTOS: {
    SUCCESS: 'Зураг амжилттай орууллаа',
    FAIL: 'Зураг оруулж чадсангүй',
  },
  BID_COMMENTS: {
    PLACEHOLDER: 'Сэтгэгдэл текстээ бичнэ үү',
    SEND: 'Илгээх',
    YOU: 'Та',
    MANAGER: 'Менежер',
    SUCCESS: 'Сэтгэгдэл амжилттай орууллаа',
    FAIL: 'Сэтгэгдэл оруулж чадсангүй',
    ENTER_COMMENT: 'Сэтгэгдэл оруулах',
  },
  LOT_CAROUSEL: {
    DELETE_PHOTO: 'Энэ зургийг устгахдаа итгэлтэй байна уу?',
    DELETE_SUCCESS: 'Зураг амжилттай устгагдлаа',
    DELETE_FAIL: 'Зураг устгахад алдаа гарлаа',
    MOVE_LEFT: 'Зүүн тийш хөдөлгөх',
    MOVE_RIGHT: 'Баруун тийш хөдөлгөх',
    SAVE_CHANGES: 'Өөрчлүүлэлтийг хадгалах',
    DELETE_PHOTO_TOOLTIP: 'Зураг устгах',
    AUCTION_LIST_TIP_MODAL: {
      TITLE: 'Дуудлага худалдааны хуудсыг хэрхэн унших вэ?',
      TIPS: {
        GENERAL: {
          LOT_NUM: 'Багцын дугаар',
          VEHICLE_PARAMS: 'Тээврийн хэрэгслийн үзүүлэлтүүд',
          FIRST_REG_DATE: 'Эхний бүртгэлийн огноо',
          ENGINE_CAPACITY: 'Хөдөлгүүрийн багтаамж',
          MODEL_NAME: 'Загварын нэр',
          CAR_NAME: 'Машины нэр',
          CAR_HISTORY: 'Машины түүх',
          CHASSIS_MODEL: 'Их биеийн загвар',
          CHASSIS: 'Их бие',
          CHASSIS_MODIFICATION: 'Их биеийн өөрчлөлт',
          CHASSIS_NUM: 'Буруу арлын дугаар',
          CHASSIS_GRADE: 'Их биеийн зэрэг',
          CHASSIS_TYPE: 'Их биеийн төрөл',
          TECH_INSPECTION_DEADLINE: 'Техникийн хяналтын эцсийн хугацаа',
          DOORS: 'Хаалганууд',
          MODEL_VERSION: 'Загварын хувилбар',
          DRIVETRAIN_TYPE: 'Хөтлөгчийн төрөл',
          INTERIOR: 'Дотор тал',
          INTERIOR_GRADE: 'Дотор талын зэрэг',
          EXTERIOR_GRADE: 'Гадна талын зэрэг',
          MILEAGE: 'Явсан гүйлт',
          GRADE: 'Зэрэг',
          VEHICLE_COLOR: 'Машины өнгө',
          ORIGINAL_COLOR: 'Үйлдвэрээс гарсан өнгө',
          SHIFTER_LOCATION: 'Араа солих бариулын байршил',
          COLOR_CODE: 'Өнгөний код',
          INTERIOR_COLOR: 'Дотор өнгө',
          INTERIOR_SEATS: 'Дотор тал, суудал',
          YEAR: 'Он',
          MODEL_YEAR: 'Загварын гарсан он',
          IMPORT_TYPE: 'Дилер',
          IMPORT: 'Импорт',
          IMPORT_HISTORY: 'Импортын түүх',
          IMPORT_CLASS: 'Импортын ангилал',
          USE_TYPE: 'Хэрэглэгдэх төрөл',
          PERSONAL_RENT: 'Хувийн/Түрээс',
          MONTH_DAY: 'Сар өдөр',
          MONTH: 'Сар',
          DAY: 'Өдөр',
          STEERING_WHEEL: 'Жолооны хүрдны байршил',
          STEERING_WHEEL_CONDITION: 'Жолооны хүрдний байдал',
          LEFT_RIGHT: 'Зүүн/Баруун',
          TECH_INSP: 'Техникийн үзлэг',
          RECYCLING_FEE: 'Дахин боловсруулах хураамж',
          RECYCLE_TICKET: 'Дахин боловсруулах тасалбар',
          SEATS: 'Суудал',
          SEATING_CAPACITY: 'Суудлын багтаамж',
          CARRYING_CAPACITY: 'Даацын багтаамж',
          TRANSMISSION: 'Хурдны хайрцаг',
          ORIGINAL_EQUIPMENT: 'Оригнал тоног төхөөрөмж',
          VEHICLE_DIMENSIONS: 'Тээврийн хэрэгслийн хэмжээ',
          SUNROOF: 'Люк, агаарын нээлхий',
          LEATHER_INTERIOR: 'Арьсан интерьер',
          LEATHER_SEATS: 'Арьсан суудал',
          SEATS_CONDITION: 'Суудлын байдал',
          ALLOY_WHEELS: 'Хайлшин обуд',
          TV: 'ТВ',
          TV_ORIGINAL: 'ТВ-оригнал',
          AUDIO: 'Аудио',
          WHEEL_COVERS: 'Обудны таг',
          BODY_KIT: 'Боди кит',
          SIDE_MIRRORS: 'Хажуугийн толь',
          ABRASIONS: 'Элэгдэл',
          NOT_WORK_ABSENT: 'Ажилгүй, Байхгүй',
          FUEL_TYPE: {
            TITLE: 'Шатахууны төрөл',
            DESCR: 'Энэ тохиолдолд бензинийг дугуйлсан, баруун талд дизель',
          },
          RE_REG_DEADLINE: {
            TITLE: 'Баримт бичгийг дахин бүртгүүлэх эцсийн хугацаа',
            DESCR: 'Машиныг дахин бүртгүүлэх огноо',
          },
          WILL_BE_SENT_LATER: {
            TITLE: 'Дараа илгээгдэх болно',
            DESCR: 'Илгээх нэмэлт зүйлс',
          },
          DIMENSIONS: {
            TITLE: 'Хэмжээ',
            DESCR: 'Урт-өргөн-өндөр',
          },
          PURCHASED_FROM_OWNER: {
            TITLE: 'Эзэмшигчээс худалдаж авсан',
            DESCR: 'Тийм/Үгүй',
          },
          CONDITIONER: 'Агааржуулагч',
          POWER_STEERING: 'Цахилгаан жолооны хүрд',
          GPS: 'GPS',
          POWER_WINDOWS: 'Цахилгаан цонх',
          AIRBAG: 'Ослын дэр',
          ABS: 'ABS',
          TUNER: 'Tuner',
          FW: 'Урд салхины шил',
          SELLER_NOTES: 'Худалдагчийн тэмдэглэл',
          PLATE_NUM: 'Улсын дугаар',
          SERIAL_NUM: 'Серийн дугаар',
          NOTES: 'Тэмдэглэл',
          INSPECTOR_NOTES: 'Байцаагчын тэмдэглэл',
          MARKED_AVAILABLE: 'Зөвхөн боломжтой бол тэмдэглэнэ',
          WINDSHIELD: 'Салхины шил',
          SCRATCHES: 'Зураас',
          REPLACEMENT_REQUIRED: 'Солих шаардлагатай',
          LENGTH: 'Урт',
          WIDTH: 'Өргөн',
          HEIGHT: 'Өндөр',
          DAMAGE_SCHEME: {
            TITLE: 'Гэмтлийн схем',
            DESCR: 'Та гэмтлийн тэмдгийн талаар илүү ихийг мэдэх боломжтой',
            LINK: 'Энэ холбоосыг дагана уу',
          },
          SPARE_WHEEL: 'Илүү дугуй',
          INSURANCE: 'Даатгал',
          NEW_CAR_INSURANCE: 'Шинэ машины даатгал',
          JP_DOCS: 'Японы баримт бичиг',
          DEALER_SELF_IMPORT: 'Дилер/импорт',
          CAR_OPTIONS: 'Машины нэр/Хаалга/Их биеийн төрөл/Хувилбар',
          A_SCRATCHES: 'A - зураас',
          X_CRACKS_BROKEN: 'X - хагарал, эвдэрэл',
          A_SCRATCHES_BROKEN: 'A - зураас, эвдэрэл',
          SCRATCHES_NEED_REPLACE: 'Зураас・Солих шаардлагатай',
          SERVICE_BOOK: {
            TITLE: 'Үйлчилгээний ном',
            DESCR: 'Тийм/Үгүй',
          },
          COLOR_DESCR_1: '元色 - үйлдвэрийн өнгө',
          COLOR_DESCR_2: '色替 - дахин будсан',
        },
        USS: {
          CAR_HISTORY: {
            TITLE: 'Машины түүх',
            DESCR: 'Хувь хүн уу, түрээс үү, эсвэл өөрөөр ашиглаж байсан уу',
          },
          FIRST_REG_JP_CHRONO: 'Анхны бүртгэлийн дагуу. Японы он дарааллын дагуу',
          TECH_INSPECTION_VALIDITY_PERIOD: {
            TITLE: 'Техникийн хяналт шалгалтын хүчинтэй хугацаа',
            DESCR:
              'Японд тээврийн хэрэгслийн техникийн хяналт хүчинтэй байх огноо. Шинэ машинд 3 жил',
          },
          CONDITIONER: {
            TITLE: 'Агааржуулагч',
            DESCR:
              'Энэ тохиолдолд энэ нь хэд хэдэн мэдрэгч, электрон цаг уурын хяналтын нэгжээр тоноглогдсон агааржуулагч, шүүлтүүр, халаалтын систем юм.',
          },
          DOCS_VALIDITY_PERIOD:
            'Худалдан авсан машиныг дахин бүртгүүлэхээс өмнө баримт бичгийн хүчинтэй байх хугацаа. Энэ нь зөвхөн улсын дугаартай автомашинд хамаатай',
        },
        T_C_AA: {
          LOT_NUM: {
            TITLE: 'Багцын дугаар',
            DESCR:
              'Багцын дугаарын доор байгаа тэмдэг нь тухайн машин анх удаа дуудлага худалдаанд орж байгаа эсэхийг харуулдаг',
          },
          TAA_AUCTION_NOTES: 'TAA дуудлага худалдааны тэмдэглэл',
          CAA_AUCTION_NOTES: 'САА дуудлага худалдааны тэмдэглэл',
          CAR_COLOR_DESCR: 'Хэрэв машиныг дахин будсан бол баруун талд тэмдэглээрэй',
        },
        JU: {
          MODEL_VERSION: 'Загвар/хувилбар',
          COLOR_N_COLOR_CODE: 'Тээврийн хэрэгслийн өнгө, өнгөний код',
          CAR_DISADVANTAGES_WINDSHIELD:
            'Зураас, чулууны үйрмэг, хагарал, засварын шинж тэмдэг, солих шаардлагатай',
          CAR_DISADVANTAGES_INTERIOR: 'Зураас, шороо, түлэгдэлт, нүх, элэгдэл, зүсэлт, урагдал',
        },
        LAA: {
          DEALER_INSURANCE: 'Дилерийн даатгал',
          OWNERS_MANUAL: 'Эзэмшигчийн гарын авлага',
          INTERIOR_OPTIONS: 'Зураас • Утсан • Нүх • Шороон • Урагдал',
          KEYS_OFFICE: {
            TITLE: 'Түлхүүр нь дуудлага худалдааны төвд байна',
            DESCR:
              'Хэрэв шалгасан бол түлхүүрүүд дуудлага худалдааны газарт хадгалагдана. Машин хаалттай',
          },
        },
        ARAI: {
          RIGHT_STEERING_WHEEL: 'Баруун талын жолооны хүрд',
          INSPECTOR_NOTES_DESCR_1: 'Эхний мөр - Машин ажиллаж байгаа эсэх тухай мэдээлэл',
          INSPECTOR_NOTES_DESCR_2:
            'Хоёр дахь мөр - Хэрэв машин өөр хашаанд байгаа бол машин авчрах тухай мэдээлэл',
          INSPECTOR_NOTES_DESCR_3: 'Бусад байцаагчийн тэмдэглэл',
        },
        HERO: {
          NAME_MODEL: 'Машины нэр, загварын хувилбар',
          WARNING: 'Анхааруулга!',
          G_GASOLINE: 'G-бензин',
          YEN: 'Иен',
        },
        IAA_OSAKA: {
          ODOMETER_PROBLEMS: {
            TITLE: 'Одоогийн явсан гүйлтийн тэмдэглэл',
            DESCR: 'Солигдсон - гүйлт мушгирсан - гүйлт тодорхойгүй',
          },
          NOX: {
            TITLE: 'NOx (азотын исэл)',
            DESCR: 'Стандартад нийцсэн эсвэл хангаагүй',
          },
          SELLER_TYPE: {
            TITLE: 'Импортын ангилал',
            DESCR: 'Дилер-Импорт',
          },
          X_OPTIONS: 'X - үйрмэг, хагарал, солих шаардлагатай',
          CAR_DISADVANTAGES: 'Толбо, хагарал, зураас, шороо, элэгдэл',
          SCRATCHES_ABRASIONS: 'Зураас - элэгдэл',
        },
        MIRIVE: {
          INNER_AUCTION_NAME: 'Дотоод дуудлага худалдааны нэр',
          REGISTRATION: 'Бүртгэл',
          PRECAUTIONARY_MEASURES: 'Урьдчилан сэргийлэх арга хэмжээ',
          RE_REG_PERIOD: 'Дахин бүртгүүлэх хугацаа',
          BUY_CAR: 'Машин худалдаж авах',
          DEALER_MILEAGE: 'Дилер ・ явсан гүйлт',
          LEFT_RIGHT_STEERING_WHEEL: 'Баруун эсвэл зүүн гар талын жолоодлоготой',
          WHEELS_SCRATCHES_BROKEN: 'Хаалганы толь (зураас/эвдэрсэн)',
          REAR_VIEW_MIRRORS:
            'Биеийн байдал (бага зэргийн зураас/бага зэргийн хонхорхой/засвар шаардлагатай)',
          ADDITIONALLY: 'Нэмж хэлэхэд',
        },
      },
      BUTTON: {
        TOOLTIP_TITLE: 'Дуудлага худалдааны зарын дэлгэрэнгүйг дарж үзнэ үү',
      },
    },
  },
  LOT_STATISTICS: {
    ENGINE_VOLUME: 'Хөдөлгүүрийн хэмжээ',
    ALERT:
      'It is possible that the current lot was previously auctioned, you can check in the list',
  },
  LOT_INFO: {
    TRUCK: 'Трак',
    TITLE: 'Лот',
    NAME: 'Нэр',
    AUCTION: 'Дуудлага худалдаа',
    AUCTION_DATE: 'Дуудлага худалдааны огноо',
    YEAR: 'Он',
    CHASSIS_ID: 'Арлын дугаар',
    COLOR: 'Өнгө',
    ENGINE_CC: 'Хөдөлгүүрийн хэмжээ',
    MILEAGE: 'Гүйлт',
    INSPECTION: 'Хяналт',
    SCORE: 'Оноо',
    START_PRICE: 'Эхлэх',
    SOLD_FOR: '-аар зарагдсан',
    TRADING_RESULT: 'Арилжааны үр дүн',
    AVERAGE_PRICE: 'Дундаж үнэ',
    YOUR_BID: 'Таны бид',
    RECOMMENDED_BID_AMOUNT: 'Санал болгох бид-ийн үнийн дүн',
    RECOMMENDED_BID_NO_FRESH_DATA: 'Сүүлийн үеийн мэдээлэл алга',
    APPROVE_PARTICIPATION: 'Миний оролцохыг зөвшөөр',
    DENY_PARTICIPATION: 'Бид цуцлах',
    PARTICIPATION_ACCEPTED: 'Оролцохыг хүлээн зөвшөөрсөн',
    PARTICIPATION_CANCELED: 'Оролцохыг цуцалсан',
    NEGOTIATIONS_PRICE: 'Хэлэлцээрийн эхлэх үнэ',
    APPROVE_PARTICIPATION_IN_NEGOTIATIONS: 'Оролцохыг хүлээн зөвшөөрсөн',
    NEGOTIATIONS_ACCEPTED: 'Хэлэлцээрийг хүлээн зөвшөөрсөн',
    COUNTDOWN_TO_FINISH: 'Дуусах цаг',
    WAS_OVERBID: 'Та бид дээр тэргүүлэхгүй байна',
    END_SOON_HINT: 'Лот-ийн бид авах хугацаа дууслаа',
    BIDDING_OVER: 'Бид дууслаа',
    FIXED_PRICE: 'Үнэ',
    BEST_BID: 'Өөр нэг оролцогчийн бид',
    PRODUCTION_DATE: 'Үйлдвэрдэгдсэн огноо',
    EXISTS: 'Байдаг',
    NOT_EXISTS: 'Байхгүй',
    DRIVE: 'Хөтлөгч',
    GROUP: 'Бүлэг',
    GEARBOX_TYPE: 'Хурдны хайрцагны төрөл',
    OBJECT_DATA: 'Лот-ийн мэдээлэл',
    BIDS: 'Бид',
    GUIDE: {
      MY_BIDS: {
        TITLE: 'Бидүүд хуудас',
        DESCR:
          'Энэ бол таны бид-ийн хуудас юм. Эндээс та одоогийн болон хуучин бид-үүдээ харж, одоогийн бид-ээ цуцалж болно',
      },
      FILTER: {
        TITLE: 'Шүүлтүүр',
        DESCR: 'Шалгууруудын дагуу шаардлагатай бид-ийг олохын тулд шүүлтүүрийг ашиглана уу',
      },
      STATUS: {
        TITLE: 'Байдал',
        DESCR:
          'Ямар нэг байдлаас хамааран тодорхой бид-үүдийн хооронд шилжихийн тулд энэ табуудыг ашиглана уу',
      },
      BIDS_LIST: {
        TITLE: 'Бид-Ний жагсаалт',
        DESCR: 'Дээрх шалгуур дээр үндэслэн таны бид энд харагдана',
      },
      COMMENT: {
        TITLE: 'Коммент',
        DESCR: 'Та бидэнд хэрэгтэй ямар нэгэн чухал мэдээллийг комментоор үлдээж болно',
      },
      DELETE_BID: {
        TITLE: 'Бид цуцлах',
        DESCR:
          'Та дуудлага худалдаанд оролцохыг хүсэхгүй байгаа бол бид-ээ хасах хүсэлтээ бидэнд мэдэгдэнэ үү. Хэрэв танд нэг лот-ын хэд хэдэн бид байгаа бол тус бүрээр нь хасах хүсэлт илгээх шаардлагатайг анхаарна уу.',
      },
      FINISH: {
        TITLE: 'Мэдээлэл дууслаа',
        DESCR: 'Анхаарал хандуулсанд баярлалаа',
      },
    },
  },
  GEARBOX_TYPES: {
    automatic: 'Автомат хурдны хайрцаг',
    manual: 'Механик хурдны хайрцаг',
  },
  LOT_PHOTO_REQUEST: {
    SEND: 'Зураг авах хүсэлт',
    SUCCESS: 'Санал хүсэлт амжилттай илгээгдлээ',
    FAIL: 'Санал хүсэлт илгээгдсэнгүй',
    ARAI_SUCCESS: 'Зураг амжилттай орууллаа',
    ARAI_FAIL: 'Зураг оруулахад алдаа гарлаа',
  },
  PRODUCTION_DATE_PAYLOAD: {
    date: 'Үйлдвэрлэгдсэн огноо',
    trimcode: 'Бүрээсний код',
    modelname: 'Загвар',
    modelcode: 'Загварын код',
    engineno: 'Хөдөлгүүр',
    colorcode: 'Өнгөний код',
    code: 'Тайлбар',
    catalogno: 'Каталогын дугаар',
    transcode: 'Хурдны хайрцаг',
    vincode: 'Арлын дугаар',
    modelcat: 'Ангилал',
    blockcode: 'Хөдөлгүүрийн мэдээлэл',
    gradecode: 'Зэрэг',
    colorrgb: 'Өнгөний RGB',
    colorname: 'Өнгөний нэр',
  },
  NOTIFICATION_SETTINGS: {
    TITLE: 'Мэдэгдэл',
    FULL_TITLE: 'Мэдэгдлийн тохиргоо',
    NAME: 'Мэдэгдлийн нэр',
    CONNECT_TELEGRAM: 'Telegram-ийн мэдэгдлийг холболоо',
    TELEGRAM_NOT_CONNECTED: 'Телеграм данс холбогдоогүй байна',
    NAMES: {
      AkebonoBidBought: 'Лот авагдлаа',
      AkebonoBidTranslationCompleted: 'Лот-ийн орчуулга дууслаа',
      AkebonoBidWasOverbid: 'Бид хэтрүүлсэн',
    },
  },
  BIDS_SETTINGS: {
    TOOLTIPS: {
      HIDE_MY_BIDS_AMOUNT_HINT:
        'Идэвхжүүлсэн үед бусад хэрэглэгчид таны бид-ийн үнийн дүнг харахгүй, харин та бусад хэрэглэгчдийн бид-ийн үнийн дүнг харах боломжгүй.',
      HIDE_BIDS_ATTENTION: 'Анхаар! Та энэ тохиргоог ганц л удаа өөрчлөх боломжтой.',
    },
    KEYS: {
      hideAmountBid: 'Бид-ийн үнийн дүнг нуух',
      bidTranslationByDefault: 'Бид-нд лот-ийн орчуулгийг хүсэх',
      subscribeToViewBestBid: 'Бусад үйлчлүүлэгчийн бид-ийг харах',
      ignoreSmallBidWarning: 'Тендерт ялах магадлал бага байгаа тухай анхааруулгыг үл тоомсорлох',
    },
  },
  TRANSLATION_SETTINGS: {
    DEFAULT_TRANSLATION_MEDIA_TYPE: 'Өгөгдмөл орчуулгын төрөл',
    DEFAULT_AUDIO_TRANSLATION_SPEED: 'Өгөгдмөл орчуулгын тоглуулах хурд',
    PREFERRED_LANGUAGE_FOR_TRANSLATION_AND_NOTIFICATION: 'Орчуулга болон мэдэгдлийн хэлийг сонгох',
    TRANSLATION_MEDIA_TYPES: {
      AUDIO: 'Аудио',
      TEXT: 'Текст',
    },
  },
  SETTINGS: {
    NAME: 'Нэр',
    VALUE: 'Үнэ',
    TITLE: 'Тохиргоо',
    NOTIFICATIONS: 'Мэдэгдэл',
    BIDS: 'Бид',
    OTHER: 'Бусад',
    TRANSLATIONS: 'Орчуулга',
  },
  LOT_TRANSLATION: {
    ICONS: {
      damaged: 'Доголдол',
      forklift: 'Жолоодох боломжгүй',
      repainted: 'Дахин будсан',
      check: 'Check engine',
      equipped_for_disabled: 'Тэргэнцэртэй хүнд зориулагдаж тоноглогдсон микро',
      drowned: 'Живсэн машин',
      sanctions: 'Хориг арга хэмжээ',
      dashboard_lamp_on: 'Хянах самбарын гэрэл ассан',
      left_drive: 'Зүүн жолооны хүрдгүй',
      second_keys: 'Second key',
      diesel: 'Diesel vehicle',
      natural_gas: 'Natural gas vehicle',
    },
    ICONS_TOOLTIPS: {
      damaged: 'Машины эвдрэл. Менежертэй холбогдоно уу',
      forklift: 'Өөрийнхөө хүчээр хөдөлж чадахгүй',
      repainted: 'Өөрийн будаг биш',
      check: 'Check engine ассан',
      equipped_for_disabled: 'Тэргэнцэртэй хүнд зориулагдаж тоноглогдсон',
      drowned: 'Живсэн машин',
      sanctions: 'Хориг арга хэмжээний жагсаалтанд орсон машин',
      dashboard_lamp_on: 'Нэг эсвэл тэрнээс олон хянах самбарын гэрэл ассан',
      left_drive: 'Машин нь зүүн талын хүрдтэй биш',
      second_keys: 'Has second key',
      diesel: 'Diesel vehicle',
      natural_gas: 'Natural gas vehicle',
    },
  },
  SPECIAL_BID_ALERT: {
    ONE: {
      FIRST_LINE: 'Эрхэм үйлчлүүлэгчид ээ!',
      SECOND_LINE:
        'Японд бензиний үнэ нэмэгдсэнтэй холбоотойгоор манай тооцоолуурын зардал өөрчлөгдсөн. Та бүхэн шинэ тарифтай танилцана уу.',
    },
    TWO: {
      SECOND_LINE:
        'Худалдан авсан лот-ын төлбөрийг ажлын 3 хоногийн дотор төлөх ёстойг сануулж байна. Хэрэв таны худалдан авсан лот-ын төлбөр Япон дахь манай дансанд 10 хоногийн дотор орж ирэхгүй бол өр барагдуулах хүртэл өдөр бүр төлөх төлбөрийн 1%-тай тэнцэх хэмжээний торгууль ногдуулна. Үл хамаарах зүйл бол төлбөрийг Японы банкинд баталгаажуулах явдал юм.',
    },
  },
  LOT_STATUSES: {
    STATUS: 'Лот-ийн байдал',
    INITIAL: 'Unhandled',
    SOLD: 'Зарагдсан',
    NEGOTIATIONS: 'Хэлэлцээ',
    WON: 'Худалдан авсан',
    CANCELED: 'Дуудлага худалдаанаас буцаан',
    UNSOLD: 'Зарагдаагүй',
    NEGOTIATION_SOLD: 'Хэлэлцээрээр зарна',
    REMOVED: 'Устгасан',
  },
  BOUNDARY_INFO: {
    NOT_BOUNDARY: '{{date}}-С өмнө ОХУ-д импортлох',
    EARLY: '{{date}}-Ээс хойш ОХУ-д импортлох',
    BOUNDARY: 'Хилийн зурвас! {{date}}-с өмнө ОХУ-д импортлох?',
    OVERDUE: 'Төлбөрт тооцогдоогүй?',
  },
  INTERESTING: {
    TRUE: 'Энэ лот-ийг таалагдсанд нэм',
    FALSE: 'Энэ лот-ийг сонирхохгүй байна гэж тэмдэглэ',
  },
  SPECIAL_ALERTS: {
    HAS_DEBTS:
      'Автомашины төлбөрийг хувийн дансанд шилжүүлсэн өдрөө мэдэгдэнэ үү. Үгүй бол таны төлбөр тодорхойгүй болж, машинд торгууль ногдуулах болно.',
  },
  SHARED: {
    SHARE: 'Хуваалцах',
    SHARE_LOT: 'Лот-оо хуваалцах',
    SHARE_LIST: 'Жагсаалтаа хуваалцах',
    COPY_SHARED_SUCCESS: 'Лот-ын холбоосыг хуулсан',
    COPY_LIST_SUCCESS: 'Жагсаалтын холбоосыг хуулсан',
    OPEN: 'Нээх',
    CLOSE: 'Хаах',
    SELECT_LIST: 'Жагсаалт сонгох',
    ADD_NEW_LIST: 'Шинэ жагсаалт үүсгэх',
    LISTS_INCLUDES: 'Лот жагсаалтанд багтсан байна',
    LIST_ADD: 'Нэмэх',
    MOVE: 'Нүүлгэх',
    MOVE_TO: 'Тийш нь нүүлгэх',
    DELETE_LIST: 'Жагсаалтыг устгах',
    CLOSE_ACCESS: 'Хандалтыг хаах',
    VIEW_ALL: 'Бүгдийг үзэх',
  },
  CHANNEL: {
    LABEL: 'Суваг',
    DELETE: 'Суваг устгах',
    EDIT: 'Суваг засварлах',
    CREATE: 'Суваг үүсгэх',
    CHANNEL_TITLE: 'Сувгийн нэр',
    COMPANY_NAME: 'Компанын нэр',
    DESCRIPTION: 'Тайлбар',
    UPLOAD_LOGO: 'Лого оруулах',
  },
  MANUAL_LOTS: {
    LABEL: 'Нэмэлтээр',
    UPLOAD: 'Зураг оруулах',
    CREATE: 'Лот үүсгэх',
    ADD_LOT: 'Лот нэмэх',
  },
  FILTER: {
    FILTER: 'Шүүлтүүр',
    RESET: 'Дахин тохируулах',
    SEARCH: 'Хайх',
    REQUIRED: 'Шаардлагатай',
  },
  MILEAGE_FILTER: {
    FROM: 'Гүйлт багадаа',
    TO: 'Гүйлт ихдээ',
  },
  YEAR_FILTER: {
    FROM: 'Он багадаа',
    TO: 'Он ихдээ',
  },
  LOT_CHANGES: {
    TITLE: 'Лот-Ийн өөрчлөлтийн түүх',
    DATE: 'Огноо',
    DESCRIPTION: 'Тайлбар',
    VERSION: 'Хувилбар',
    AUTHOR: 'Зохиогч',
    CHANGES: {
      CREATED: 'Лот үүсгэсэн',
      TRANSLATIONS_COUNT: 'Орчуулгын тоо өөрчлөгдсөн',
      ICONS: 'Таних тэмдэг өөрчлөгдсөн',
      VIN: 'Арлын дугаар өөрчлөгдсөн',
      PRODUCTION_DATE: 'Үйлдвэрлэгдсэн огноо өөрчлөгдсөн',
      IMAGES: 'Зураг өөрчлөгдсөн, одоогийн тоо',
      THUMBNAILS: 'Тойм зураг өөрчлөгдсөн, одоогийн тоо',
      PHOTOS_FETCHED: 'Лот зураг татагдсан',
      STATUS: 'Төлөв өөрчлөгдсөн',
      UPDATED: 'Лот шинэчлэгдсэн',
      START_PRICE: 'Эхлэх үнэ өөрчлөгдсөн',
      SOLD_PRICE: 'Борлуулсан үнэ өөрчлөгдсөн',
      NEGOTIATIONS_PRICE: 'Хэлэлцээрийн үнэ өөрчлөгдсөн',
      AUCTION_DATETIME: 'Дуудлага худалдааны хугацаа шинэчлэгдсэн',
    },
    BUTTON_TITLE: 'Lot history',
  },
  ARAI_KENKI: {
    LABELS: {
      EXCAVATOR: 'Экскаватор',
      MINI_EXCAVATOR: 'Мини экскаватор',
      WHEEL_LOADER: 'Дугуйт ачигч',
      MINI_WHEEL_LOADER: 'Жижиг ачигч дугуйтай',
      SKID_STEER_LOADER: 'Жижиг ачигч',
      GRADER: 'Гадаргууг тэгшлэгч Грейдер',
      ASPHALT_FINISHER: 'Асфальт өнгөлөгч',
      CRANE: 'Кран',
      ROUGH_TERRAIN_CRANE: 'Тогтворжуулагчтай кран',
      TRUCK_CRANE: 'Трак кран',
      ALL_TERRAIN_CRANE: 'Бүх газрын трак кран',
      AERIAL_WORK_PLATFORM: 'Өргөгч тавцан',
      CRAWLER_CRANE: 'Гинжит кран',
      MINI_CRAWLER_CRANE: 'Мини гинжит кран',
      COMPRESSOR: 'Компрессор',
      MOBILE_CRUSHER: 'Хайрга бутлуур',
      ATTACHMENTS: 'Хавсралт',
      ATTACHMENTS_ETC: 'Хавсралт гэх мэт',
      BLADE: 'Шанага',
      BUCKET: 'Ковш',
      BREAKER: 'Хагалагч',
      CUTTER: 'Таслагч',
      CRUSHER: 'Бутлуур',
      CONCRETE_CUTTER: 'Бетон таслагч',
      CONSTRUCTION_MISCELLANEOUS: 'Барилгын элдэв зүйлс',
      VIBRO: 'Вибро',
      VIBRO_HAMMER: 'Вибро алх',
      FORK: 'Сэрээ',
      FORKLIFT_NAIL: 'Сэрээт өргөгчийн хадаас',
      FLOODLIGHT: 'Гэрэлтүүлэг',
      MAGNET: 'Соронз',
      PLATE: 'Хавтан',
      RAMMER: 'Бутлагч',
      BULLDOZER: 'Бульдозер',
      SHOVEL_LOADER: 'Шанагатай ачигч',
      FORKLIFT: 'Сэрээт өргөгч (По)',
      BATTERY_FORKLIFT: 'Баттереитай сэрээт өргөгч',
      REACH_FORKLIFT: 'Өндрийн сэрээт өргөгч',
      RICE_TRANSPLANTER: 'Будаа суулгагч машин',
      ROLLER: 'Индүүдэгч',
      COMBINE_ROLLER: 'Хосолсон индүүдэгч',
      SOIL_ROLLER: 'Хөрс индүүдэгч',
      TIRE_ROLLER: 'Дугуйтай индүүдэгч машин',
      TANDEM_ROLLER: 'Асфалт индүүдэгч машин',
      HAND_GUIDED_ROLLER: 'Гараар удирддаг индүүдэгч',
      MACADAM_ROLLER: 'МакАдам индүүдэгч машин',
      CRAWLER_DUMP: 'Гинжит буулгагч',
      CRAWLER_CARRIER: 'Гинжит буулгагч',
      CRAWLER_TRUCK: 'Гинжит буулгагч',
      WHEEL_CARRIER: 'Запас дугуй үүрэгч',
      GENERATOR: 'Генератор',
      WELDER_GENERATOR: 'Гагнуурчны генератор',
      AGRICULTURAL_MACHINE_ETC: 'Хөдөө аж ахуйн машин г.м',
      CONSTRUCTION_MACHINE_ETC: 'Барилгын машин г.м',
      OFFROAD_DUMP_TRUCK: 'Асгагч',
      SNOW_REMOVING_MACHINE: 'Цас арилгагч машин',
      STABILIZER: 'Тогтворжуулагч',
      SPECIAL_PURPOSE_VEHICLE: 'Тусгай зориулалтын механизм',
      ROAD_CUTTER: 'Зам таслагч',
      TURRET_TRUCK: 'Өргөгч трактор',
      LOGGING_TRACTOR: 'Мод бэлтгэдэг трактор',
      TOWING_TRACTOR: 'Чирэгч трактор',
      SMALL_MACHINES: 'Жижиг машинууд',
      TRACTOR: 'Трактор',
      COMBINE: 'Нэгтгэх',
      AGRICULTURAL_MACHINE_ATTACHMENT: 'Хөдөө аж ахуйн механизмийн хавсралт',
    },
  },
  PRESET_UNSUBSCRIBE: {
    UNSUBSCRIBING: 'Захиалгат үйлчилгээг цуцлах',
    SUCCESS: 'Шүүх тохиргоо олдсонгүй',
    FAIL: 'Шүүлтүүрийн урьдчилсан тохируулга олдсонгүй',
    GO_HOME: 'Home цэс рүү буцах',
  },
  LOT_PROBLEM_ALERTS: {
    LOT_DATA_CHANGED: 'Лот өөрчлөгдсөн тул Бид-ийг хүлээж авахгүй',
    BIDS_LIMIT_REACHED: 'Өдрийн Лот-ийн хязгаарт хүрсэн байна',
    LOT_BIDS_LIMIT_REACHED: 'Лот-Ийн Бид-ийн хязгаарт хүрсэн байна',
  },
  CONNECT_TELEGRAM: {
    TITLE: 'Telegram-Тай холбогдож байна',
    CONNECT_BUTTON: 'Мэдэгдлийг Telegram-тай холбох',
  },
  SUBSCRIBE_TO_BEST_BID: {
    CONFIRMATION_1:
      '"Bid Display" үйлчилгээ нь 30 хоногийн дотор өөр үйлчлүүлэгчийн Лот-ийн одоогийн Бид-ийг харах боломжийг танд олгоно. Энэ үйлчилгээг авахын тулд сүүлийн 30 хоногт 10 ба түүнээс дээш худалдан авалт хийсэн байх шаардлагатай',
    CONFIRMATION_2:
      'Зардал нь <span class="red">100,000 иен</span> бөгөөд таны урьдчилгаа төлбөрөөс хасагдах болно.',
    ATTENTION: 'Анхаар!',
    OK_TEXT: 'Залгах',
    TITLE: 'Бусад үйлчлүүлэгчдийн Бид-ийг харах',
    DAYS_LEFT: '{{Days}} өдөр үлдсэн',
    NO_ACTIVE_SUB: 'Идэвхтэй захиалгат үйлчилгээ байхгүй байна',
    HAS_ACTIVE_SUBSCRIPTION: 'Хамгийн сайн Бид-ийг харах захиалгат үйлчилгээг идэвхжүүллээ',
    SUCCESS: 'Үйлчилгээ амжилттай холбогдсон байна',
    SUCCESS_WHEN_ADVANCE_UPDATE:
      'Урьдчилгаа төлбөр орж ирснээр хэсэг хугацааны дараа үйлчилгээ идэвхжинэ',
    SUCCESS_REQUEST:
      'Урьдчилгаа төлбөрийг хүлээн авсан үед захиалгат үйлчилгээг идэвхжүүлэх хүсэлт бии болно',
    FAIL: 'Үйлчилгээг холбоход алдаа гарлаа, менежертэй холбогдоно уу.',
    MODAL_TITLE: 'Гэрээ',
    AGREEMENT_TIMER: 'Та үйлчилгээг {{seconds}}-ийн дараа идэвхжүүлж болно',
    AGREE: 'Би холболтын нөхцөл болон зардлыг зөвшөөрч байна',
    SUB_ENDS_TODAY: 'Захиалгат үйлчилгээ өнөөдөр дуусна',
    SUBSCRIBE_ON_ADVANCE_UPDATE: 'Урьдчилгаа төлбөр ирэх үед залгах',
    REQUEST_SUBSCRIBE_ON_ADVANCE_UPDATE: 'Урьдчилгаа төлбөр ирэх үед залгах хүсэлт гаргах',
    CANT_BUY_ALERT: 'Та энэ үйлчилээг авахад хангалттай мөнгө байхгүй байна',
  },
  LOT_COUNTERS: {
    VIEWS_COUNT: 'Үзэлтийн тоо: {{count}}',
    IN_FAVORITES_COUNT: 'Таалагдсан жагсаалтанд нэмэгдлээ: {{count}}',
    TOOLTIP: '{{Bidders}} үйлчлүүлэгч энэ лот-д {{bids}} бид тавьсан байна',
    BIDDERS_ENDING_1: '#ЗНАЧ!',
    BIDDERS_ENDING_2: '#ЗНАЧ!',
    BIDDERS_ENDING_5: '#ЗНАЧ!',
    BIDS_ENDING_1: '#ЗНАЧ!',
    BIDS_ENDING_2: '#ЗНАЧ!',
    BIDS_ENDING_5: '#ЗНАЧ!',
    MAKE_ENDING_1: '#ЗНАЧ!',
    MAKE_ENDING_2: '#ЗНАЧ!',
  },
  MAX_BID_COMMISSION: {
    COMMISSION: 'Шимтгэл',
    YOU_BID_ON_LOT: 'Таны Лот-д ногдох үнийн санал байх болно',
    ADDITIONAL_DEALER_COMMISSION: 'Зуучлагчийн нэмэлт шимтгэл',
    AGREE_WITH_COMMISSION: 'Би нэмэлт шимтгэлийг хүлээн зөвшөөрч байна',
    MINIMUM: 'Хамгийн бага',
    MINIMUM_DEALER_COMMISSION: 'Зуучлагчийн шимтгэлийн доод хэмжээ',
    MINIMUM_LOT_BID: 'Бид-ний доод хэмжээ',
  },
  BIRTHDATE_MODAL: {
    TITLE: 'Төрсөн огноо',
    INVALID_DATE: 'Алдаатай төрсөн огноо байна',
    ALERT: 'Үргэлжлүүлэхийн тулд та төрсөн огноо оруулна уу',
  },
  RESET_LOT: {
    TITLE: 'Лот-ын өгөгдлийг шинэчлэх',
    WARNING:
      'Лот-ын өгөгдлийг шинэчлэх нь: Лот-ын Бид, Бид-ийн санал, Лот-тэй холбоотой хэрэглэгчийн хүсэлт, Лот-ийн орчуулга зэргийг устгах болно. Та Лот-ыг шинэчлэхдээ итгэлтэй байна уу?',
  },
  AUCTION_RATING: {
    REVIEWS: 'Шүүмж',
    REVIEWS_AUCTION: '{{auctionname}}-ийн талаарх шүүмж',
  },
  USER_PURCHASE_STATISTICS: {
    BOUGHT_TODAY: 'Өнөөдрийн худалдан авалтууд',
    BOUGHT_YESTERDAY: 'Өчигдрийн худалдан авалтууд',
    BOUGHT_WEEK: '7 хоногийн худалдан авалтууд',
  },
  VAT: 'Анхаар! Энэ худалдан авалтанд НӨАТ-ын 10% татвар ноогдуулна',
  MENU: 'Цэс',
  NEWS: 'Мэдээ',
  TO_FULL_LOT: 'Бүтэн Лот',
  Целая: 'Бүтэн',
  Спецтехника: 'Тусгай зориулалтын машин механизм',
  Мотоцикл: 'Мотоцикл',
  SANCTIONED_LOT_NOT_ALLOWED: 'Хоригтой Лот-ийн мэдээллийг танай улсад үзэх боломжгүй',
  'Request already created for this lot': 'Энэ Лот-ийн хүсэлт аль хэдийн үүссэн',
  'Приём ставок на лот окончен!': 'Бид хүлээн авах хугацаа дууслаа!',
  'Конструктор 3 000 евро': 'Конструктор 3 000 евро',
  'Запчасти (палеты)': 'Эд анги (поддон)',
  'Запчасти контейнер': 'Эд анги (чингэлэг)',
  'Другие страны': 'Бусад орнууд',
  'Другие страны MOTO': 'Бусад орнууудад зөвхөн мотоцикльтой',
  'Грузовик-конструктор': 'Ачааны машины үйлдвэрлэгч',
  '(Борт) Распил перед раму пилим': 'Их биеийн өмнө зүссэн',
  '(Борт) Распил передний': 'Урдуур зүссэн',
  '(Борт) Распил зад раму пилим': 'Их биеийн хойгуур зүссэн',
  '(Борт) Распил задний': 'Хойгуур зүссэн',
  '(Борт) Распил вместе с рамой': 'Их биеийн урд хойгуур зүссэн',
  '(Борт) Передняя половина без крыльев': 'Карлангүй урд тал',
  '(Борт) Конструктор': 'Конструкторч',
  '(Борт) каркас': '#ЗНАЧ!',
  '(Берег) Распил перед раму пилим': '#ЗНАЧ!',
  '(Берег) Распил передний': '#ЗНАЧ!',
  '(Берег) Распил зад раму пилим': '#ЗНАЧ!',
  '(Берег) Распил задний': '#ЗНАЧ!',
  '(Берег) Распил вместе с рамой': '#ЗНАЧ!',
  '(Берег) Передняя половинка': '#ЗНАЧ!',
  '(Берег) Конструктор': '#ЗНАЧ!',
  '(Берег) Каркас': '#ЗНАЧ!',
  'Сумма ставки должна быть положительной': 'Бидны дүн нь эерэг утгатай байх ёстой',
  'Сумма ставки должна быть выше стартовой цены': 'Таны бид анхны үнээс өндөр байх ёстой',
  'Ваша ставка меньше ставки другого участника торгов': {
    '': 'Your bid is less than the bid of another bidder.',
    ' Вы можете поднять свою ставку, попыток осталось:':
      'Your bid is less than the bid of another bidder. You can raise your bid, tries left: {{triesLeft}}',
  },
  LowBidError:
    'Таны хэлсэн үнийн санал өөр оролцогчийн саналаас доогуур байна ЭСВЭЛ өөр нэг оролцогч илүү өндөр үнэ хэлсэн байна',
  Error: 'Алдаа',
  'Слишком много ставок выше вашей, ставка не имеет возможности победить, извините':
    'Хэт олон оролцогчийн бид таныхаас өндөр байна, дуудлага худалдаанд ялах боломжгүй боллоо, уучлаарай',
  'Для данного лота не осталось попыток повышения ставки':
    'Та энэ лот-ын үнийн саналын хязгаарт хүрсэн байна',
  'Данные лота устарели, обновите страницу':
    'Лот-Ын өгөгдлийн хугацаа өнгөрсөн тул хуудсыг дахин ачааллуулна уу',
  'В данном лоте уже есть победитель': 'Энэ лот нь зарагдсан байна',
  'No Result From API': 'Үйлдвэрлэгдсэн огноог тогтоох боломжгүй байна',
  'Incorrect Chassis No': {
    '': 'Буруу арлын дугаар',
  },
  'Chassis number entered in not correct': {
    ' Check on auction sheet to confirm the chassis number': {
      '': 'Арлын дугаарыг буруу оруулсан байна. Арлын дугаарыг баталгаажуулахын тулд дуудлага худалдааны хуудаснаас шалгана уу.',
    },
  },
  'Недостаточно прав для выполнения действия':
    'Зөвшөөрөл хангагдаагүй байна. Зөвхөн бид-д хандах эрхтэй хэрэглэгч энэ үйлдлийг гүйцэтгэх боломжтой. Менежертэйгээ холбогдоно уу.',
  'Среднее время доставки с данного аукциона до:': 'Дуудлага худалдаанаас хүргэх дундаж хугацаа',
  'Среднее время доставки документов с данного аукциона:':
    'Дуудлага худалдаанаас баримт хүргэх дундаж хугацаа',
  'в зависимости от доставщика': 'Хүргэлтийн хүнээс хамаарна',
  'У вас нет прав для перепроверки даты выпуска': 'Танд гарсан огноог шалгах эрх байхгүй',
  дней: 'Өдөр',
  'Если это Ваш платеж пожалуйста срочно отзовитесь':
    'Хэрэв энэ нь таны төлбөр бол менежертэйгээ яаралтай холбогдоно уу',
  'Request for this lot already exists': 'Энэ лотын хүсэлт аль хэдийн байна',
  Производитель: 'Үйлдвэрлэгч',
  Модель: 'Загвар',
  Кузов: 'Кузов',
  Аукцион: 'Дуудлага худалдаа',
  Оценка: 'Оноо',
  Цвет: 'Өнгө',
  КПП: 'Хурдны хайрцаг',
  'год от, до': 'Үйлдвэрлэгдсэн он. ээс рүү',
  'Пробег от, до (тыс': {
    'км)': 'Гүйлт (1000km)',
  },
  'Объем двигат': {
    ' от, до': 'Хөдөлгүүрийн багтаамж. ээс рүү',
  },
  Сброс: 'Шүүх',
  Поиск: 'Хайлт',
  PRINT: 'Print',
  'Расширенный поиск': 'Нарийвчилсан хайлт',
  Дополнительно: 'Нэмэлт',
  'Японские автоаукционы': 'Дуудлага худалдаа',
  SAVE_PRESET: 'Тохиргоог хадгалах',
  CREATE_USER_FILTER_PRESET: 'Шүүлтүүрийн тохирогоог хадгалах',
  MARKED_INTERESTING: 'Сонирхолтой',
  MARKED_UNINTERESTING: 'Сонирхолгүй',
  'Not Sold': 'Зарагдаагүй',
  成約: 'Зарагдсан',
  流れ: 'Зарагдаагүй',
  商談成約: 'Хэлэлцээрээр зарагдсан',
  'Request already created': 'Хүсэлтийг аль хэдийн үүсгэсэн',
  'Lot not found': 'Лотын зурагнууд олдсонгүй',
  'Filter preset not found': 'Шүүлтүүрийн урьдчилсан тохируулга олдсонгүй',
  'Достигнут лимит ставок в этой группе': 'Энэ бүлгийн үнийн саналын хязгаарт хүрсэн байна',
  BIDS: 'Үнийн санал',
  USER: 'Хэрэглэгч',
  LOT_BIDS_TABLE_TITLE: 'Нийт санал: {{bidsTotal}}. Нийт хэрэглэгчид: {{usersTotal}}',
  DETAILS: 'Дэлгэрэнгүй',
  NEGOTIATIONS_CONFIRM_1:
    'Та лотын талаар хэлэлцээр хийж байна. Энэ үйлдлийг буцаах боломжгүй. Та үргэлжлүүлэхдээ итгэлтэй байна уу? <br /> {{link}}',
  NEGOTIATIONS_CONFIRM_2:
    'Хэлэлцээрт ороход шаардагдах хамгийн бага үнийн санал (Бийд) нь {{sum}} байна. Бийд д оролцох боломжийг нэмэгдүүлэхийн тулд ¥20,000-ын нэмэлт санал шаардлагатай. Хэлэлцээрт оролцох санал нь санал болгож буй үнийн дүнгээс доогуур байж болохгүй. Доорх талбарт {{sum}}-аас багагүй бооцооны хэмжээг зааж өгнө үү.',
  NEGOTIATIONS_WORK: 'Лотын тохиролцоо хэрхэн явагддаг',
  NEGOTIATIONS_BID_TOO_SMALL_ERROR: 'Бийдний дүн {{хэмжээ}}-с бага байж болохгүй',
  NEGOTIATIONS_BID_TOO_LARGE_ERROR: 'Бийдний дүн {{хэмжээ}}-с их байж болохгүй',
  NEGOTIATIONS_BID_REQUIRED_ERROR: 'Хэлэлцээр хийх саналын хэмжээг зааж өгөх шаардлагатай',
  'Участие в торгах': 'Оролцоо',
  'Ожидает подтверждения для участия в торгах': 'Оролцооны баталгаажуулалтыг хүлээж байна',
  Отклонено: 'Оролцоог татгалзсан',
  'Подтверждено участие в торгах': 'Оролцоог хүлээн авлаа',
  TICKET_RULES: 'Дуудлага худалдааны нэхэмжлэлийн журам',
  EXPECTED_TIME_TO_TRANSLATE:
    'Японд энэ дуудлага худалдааны орчуулгын дундаж хугацаа ажлын цагаар:',
  'Номер лота': 'Лотын дугаар',
  'Please select your country': 'Улсаа сонгоно уу',
  'Cant plug subscription to view best bid':
    'Хамгийн сайн үнийн саналыг хардаг захиалгат үйлчилгээг идэвхжүүлж чадсангүй',
  PRODUCTION_DATE_CHECK_ERROR: 'Үйлдвэрлэсэн оны мэдээлэл олдсонгүй',
  'information not found': 'Мэдээлэл олдсонгүй',
  'company not found': 'Компани олдсонгүй',
  PRODUCTION_DATE_CHECK_REQUEST_LIMIT_REACHED:
    'Үйлдвэрлэсэг он шалгах хүсэлтийн хязгаарт хүрсэн байна',
  NO_TARGET_AUCTION_TOOLTIP:
    'Шаардлагатай аукшн жагсаалтанд байхгүй тохиолдолд, өөрийнхөө мэнэжэртэй холбогдоно уу',
  NOTATIONS: 'Тэмдэглэгээ',
  PROFILE: {
    USER_PROFILE: 'Хэрэглэгчийн профайл',
    GENERAL: 'Ерөнхий',
    TIMEZONE: 'Цагийн бүс',
    UPDATE: 'Шинэчлэх',
    FOR_CHAT: 'Чатны мэдэгдлийн хувьд',
    SECURITY: 'Аюулгүй байдал',
    PASSWORD_RULES:
      'Нууц үг нь зөвхөн латин үсэг, дор хаяж нэг том үсэг, тоо, 8 тэмдэгт агуулсан байна',
    ENTER_OLD_PASS: 'Хуучин нууц үгээ оруулна уу',
    ENTER_NEW_PASS: 'Шинэ нууц үгээ оруулна уу',
    REPEAT_NEW_PASS: 'Шинэ нууц үгээ дахин давтана уу',
    FIELD_REQUIRED: '{{field}} талбар шаардлагатай',
    RULES_ERROR: 'Нөхцөл хангагдахгүй байна',
    PASS_MISSMATCH: 'Нууц үг таарахгүй байна',
    CHANGE: 'Солих',
    LANGUAGE: 'Хэл',
    EMAIL_INCORRECT: 'Буруу цахим шуудан',
    ADD_EMAIL: 'Цахим шуудан нэмнэ үү',
    VALIDATION_FAIL: 'Зарим талбарыг буруу бөглөсөн байна',
    UPDATE_SUCCESS: 'Профайл амжилттай солигдлоо',
    UPDATE_FAIL: 'Профайлыг солиход асуудал үүслээ',
    TOO_SHORT: 'Нууц үг хэт богигохон байна',
    ONLY_LATIN: 'Нууц үг нь зөвхөн латин үсэг агуулсан байна',
    CAPITAL_CHAR: 'Нууц үгт дор хаяж нэг том үсэг орсон байна',
    ONE_DIGIT: 'Нууц үгт дор хаяж нэг тоо орсон байна',
    CHANGE_PASS_REQUIRED: 'Нууц үгээ заавал солино уу',
    LANGUAGES: {
      ru: 'Орос',
      en: 'Англи',
    },
    SUBSCRIBE_TO: '{{name}}-ийн мэдэгдлийг идэвхжүүлэх',
    NOTIFICATION_BY: '{{name}}-ээс мэдэгдэл ирсэн байна',
    SUBSCRIBE: 'Холбогдох',
    NOTIFICATION_TYPE: 'Мэдэгдлийн төрөл',
    NOTIFICATIONS: 'Мэдэгдэл',
    CONNECT_SUBSCRIBE: 'Telegram-Ийн мэдэгдлийг идэвхжүүлэх',
    DELETE_NOTIFICATION_CHAT: 'Холбогдсон Telegram-ийг устгах',
    CANCEL_DELETE_NOTIFICATION_CHAT: 'Болих',
    CONFIRM_DELETE_NOTIFICATION_CHAT: 'Устгах',
  },
  AUTO_FILTER_GUIDE: {
    '0': {
      TITLE: 'Долоо хоногийн өдөр',
      TEXT: 'Та хүссэн дуудлага худалдааны өдрийг сонгож болно',
    },
    '1': {
      TITLE: 'Үйлдвэрлэгч',
      TEXT: 'Боломжтой загваруудын жагсаалтыг багасгахын тулд машины үйлдвэрлэгчийг сонгоно уу? Энэ нь таньд дуртай үйлдвэрлэгчийнхээ машиныг олоход тусална.',
    },
    '2': {
      TITLE: 'Загвар',
      TEXT: 'Үйлдвэрдэгчийг сонгосны дараа тодорхой машины загварыг сонгоно',
    },
    '3': {
      TITLE: 'Кузов',
      TEXT: 'Хүссэн кузовоо сонго',
    },
    '4': {
      TITLE: 'Дуудлага худалдаа',
      TEXT: 'Хэрэв та тодорхой дуудлага худалдааг сонирхож байгаа бол та шүүлтүүр хэсэгт зааж өгч болно.',
    },
    '5': {
      TITLE: 'Автомашины үнэлгээ',
      TEXT: 'Дуудлага худалдааны автомашины үнэлгээ. Та асуулт хариулт хэсгээс үнэлгээний талаар илүү ихийг олж мэдэх боломжтой. Сайтын толгой хэсэгт холбоос нь байгаа.',
    },
    '6': {
      TITLE: 'Өнгө болон хурдны хайрцаг',
      TEXT: 'Тээврийн хэрэгслийн өнгө болон хурдны хайрцгийн төрлийг сонго',
    },
    '7': {
      TITLE: 'Үйлдвэрлэгдсэн он, явсан гүйлт болон хөдөлгүүрийн хэмжээ',
      TEXT: 'Хөдөлгүүрийн явсан гүйлтийг тоогоор зааж өгөх ёстой. Жишээ нь: 1500 эсвэл 75000',
    },
    '8': {
      TITLE: 'Лотын дугаар',
      TEXT: 'Та сонирхож буй лотын дугаарыг энд оруулан хайж болно',
    },
    '9': {
      TITLE: 'Хүнд механизмийн төрөл',
      TEXT: 'Та хүнд механизмийг тодорхой төрлөөр хайж болно. Жишээ нь: кран, хогны машин, автобус, бетон зуурагч гэх мэт',
    },
    '10': {
      TITLE: 'Загварын зэрэг',
      TEXT: 'Хүссэн загварын зэрэглэлээ зааж өгөх',
    },
    '11': {
      TITLE: 'Хөтлөгч',
      TEXT: 'Ямар хөтлөгчтэй машин сонирхож байгаагаа шүүж болно',
    },
    '12': {
      TITLE: 'Үйлдвэрлэсэн огноо',
      TEXT: 'Үйлдвэрлэсэн огноогоор зөвхөн бидэнд мэдээлэл нь байгаа лотоос хайна',
    },
    '13': {
      TITLE: 'Хараахан эхлээгүй дуудлага худалдаа',
      TEXT: 'Зөвхөн хараахан эхлээгүй дуудлага худалдаануудыг харуулах',
    },
    '14': {
      TITLE: 'Шүүлтүүрийн загварыг хадгалах',
      TEXT: 'Шүүлтүүрийг нэгэнт тохируулсны дараа та хадгалж болох бөгөөд цаашид нэг товшилтоор идэвхижүүлж болно',
    },
    '15': {
      TITLE: 'Шүүлтүүрийн үйлдлүүд',
      TEXT: 'Одоогийн шүүлтүүрийг дахин тохируулах эсвэл арилгах',
    },
    '16': {
      TITLE: 'Нарийвчилсан хайлт',
      TEXT: 'Дурын дуудлага худалдааг арилжаалагдсан өдрөөр сонгох',
    },
  },
  BODY_DEFECTS: {
    TITLE: 'Body defects',
    A1: 'A1 - Small scratch on varnish',
    A2: 'A2 - Scratches on paint',
    A3: 'A3 - Large scratch, possibly on metal',
    A4: 'A4 - Scratch more than 20 cm',
    A5: 'A5 - Covers the entire panel or more',
    B: 'B - Dent with scratch',
    E1: 'E1 - Small Dent',
    E2: 'E2 - Several Small Dents',
    E3: 'E3 - Lots of Small Dents',
    U1: 'U1 - Small Dent',
    U2: 'U2 - Dent',
    U3: 'U3 - Big Dent',
    W1: 'W1 - Repair/Painting (barely detectable)',
    W2: 'W2 - Repair/Painting (visible)',
    W3: 'W3 - Repair/Paint (very noticeable, should be repainted)',
    S1: 'S1 - Subtle Rust',
    S2: 'S2 - Rust',
    C1: 'C1 - Corrosion',
    C2: 'C2 - Noticeable Corrosion',
    P: 'P - The paint is different from the original',
    H: 'H - Paint has deteriorated',
    X: 'X - Element requires replacement',
    XX: 'XX - Replaced Element',
    B1: 'B1 - Small defect on bumpers (about the size of a thumb)',
    B2: 'B2 - Noticeable defect on bumpers',
    Y1: 'Y1 - Small Crack',
    Y2: 'Y2 - Crack',
    Y3: 'Y3 - Great Crack',
    X1: 'X1 - Small Crack on Windshield (approx. 1cm)',
    R: 'R - Repaired Windshield Crack',
    RX: 'RX - Repaired Crack on Windshield (needs replacement)',
    X_CORRECTION: 'X - Crack on the Windshield (needs replacement)',
    G: 'G - Glass stone crack',
  },
  HINT: 'Hint',
  AUCTION_SHEET_ZONES: 'Auction sheet zones',
  ACTIONS: 'Үйлдлүүд',
  PURCHASE_REPORT: 'Лотын Тайлан',
  BIDS_LIST: 'Бооцооны жагсаалт',
  IMPORT_BIDS: {
    TITLE: 'Bids import',
    ALERT:
      'Here you can import your bids into the EcarJP system. The import is done from a file – <a download href="/assets/import-bids-example.xls"><b>download the template</b></a>. <b>User</b> and <b>Group</b> columns are optional. After uploading the file, the system will analyze which of the lots are available for bidding. You will be able to specify the condition, group, and whether notification of the auction results is required. Upon completion of the import, the system will inform you which bids were successfully placed and which ones could not be placed due to <a href="https://ecarjp.com/article/bid-rules" target="_blank"><b>bidding rules</b></a>.',
    UPLOAD_TIP: 'Click or drag file to this area to upload',
    BID: 'Bid',
    GROUP: 'Group',
    STATUS: 'Status',
    LOT_NOT_FOUND: 'not found',
    BIDDABLE: 'Bid is possible',
    MUTATION_ERROR: 'Bid error: {{error}}',
    PLACING: 'Placing bids',
    SUCCESS: 'Success',
    FAIL: 'Failed',
    SUCCESSFULLY_PLACED: 'Successfully placed',
    TABLE_TITLE: {
      PARSED_BIDS_COUNT: 'bids received',
      PLACE_BIDS: 'Place bids',
      PLACE_SELECTED_BIDS: 'Place selected',
      RESET: 'Reset',
    },
    PARSING_ERRORS: {
      UNEXPECTED_ERROR: 'An unexpected error has occurred',
      INVALID_FIELD: 'The "{{field}}" field is not filled in or has an incorrect format',
    },
    ERRORS: {
      'bid-amount-is-not-multiple-5k': 'The bid must be a multiple of 5000',
      'bid-amount-less-then-start-price': 'The bid is less than the starting price',
      'impossible-place-group-bid': 'It is not possible to place a group bid',
      'group-limits-reached': 'Group limits have been reached',
      'no-available-bid-condition': 'There is no suitable bid condition',
      'lot-sanctioned': 'The sanctioned lot',
      'moto-overprice': 'The lot has been withdrawn from the auction',
      'time-over': 'The bidding is over',
      'lots-limit': 'The lot limit has been reached',
      'bids-limit': 'The bidding limit has been reached',
    },
  },
  WATCH_REQUEST: {
    TITLE: 'Photo inspection',
    ALERT_START_LINE:
      'Additional visual inspection of the lot. <br /><br /> The first inspection for your account is free.',
    ALERT_BASE_PRICE: 'Each subsequent inspection costs <b>{{price}} ¥</b> <br /><br />',
    ALERT_END_LINE:
      'Inspection request is available no later than 6 hours before the lot auction. The funds will be debited from your balance. If there is no amount on the balance, the funds will be debited from your deposit. Applications are processed on a first-come, first-served basis. As soon as your request is completed, a link with the results and media files will be sent to your email.',
    ALREADY_CREATED: 'You have already sent a request for a photo inspection for this lot',
    COMMENT: 'Comment (required)',
    SEND: 'Send request',
    IMAGES: 'Images',
    VIDEOS: 'Videos',
  },
  LOT_ADD_NOTE: {
    ADD_TITLE: 'Add note',
    EDIT_TITLE: 'Edit note',
    DELETE_NOTE: 'Delete this note?',
    NOTE: 'Note',
    READY: 'Ready',
  },
};
