<nz-skeleton [nzLoading]="loading$ | async" [nzActive]="true" [nzParagraph]="{ rows: 5 }">
  <section>
      <textarea
        nz-input
        [(ngModel)]="note"
        [rows]="4"
        [placeholder]="'LOT_ADD_NOTE.NOTE' | translate"
      ></textarea>
  </section>
</nz-skeleton>

<ng-container *nzModalFooter>
  <button
    nz-button
    nzType="primary"
    [disabled]="!note"
    [nzLoading]="loading$ | async"
    (click)="createOrUpdateNote()"
  >
    {{ 'LOT_ADD_NOTE.READY' | translate }}
  </button>
  <button nz-button (click)="close()">
    {{ 'CANCEL' | translate }}
  </button>
</ng-container>
