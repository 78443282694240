export const electricCars = [
  { company: 'Audi', model: 'E-TRON', frame: 'GEEASB, GEEAS' },
  { company: 'Audi', model: 'E-TRON GT', frame: 'FWEBGS' },
  { company: 'Audi', model: 'E-TRON S', frame: 'GEEAV' },
  { company: 'Audi', model: 'E-TRON Sportback', frame: 'GEEASB, GEEAS' },
  { company: 'Audi', model: 'E-TRON Sportback S', frame: 'GEEAV' },
  { company: 'Audi', model: 'Q4 Sportback E-TRON', frame: 'FZEBJ' },
  { company: 'Audi', model: 'RS E-TRON GT', frame: 'FWEBGE' },
  { company: 'Audi', model: 'SQ6 E-TRON', frame: '*' },
  { company: 'BMW', model: 'iX', frame: '12CF89S, 12CF89A, 22CF89A, 31CF93A' },
  { company: 'BMW', model: 'iX1', frame: '62EF67' },
  { company: 'BMW', model: 'iX3', frame: '42DU44' },
  { company: 'BMW', model: 'i3', frame: '1Z00, 1Z06, 8P00, 8P06' },
  { company: 'BMW', model: 'i4', frame: '72AW44, 32AW89' },
  { company: 'BMW', model: 'i7', frame: '52EJ89' },
  { company: 'FIAT', model: '500', frame: '31212, -31212-' },
  { company: 'FIAT', model: '500E', frame: '-31212-, ..., FA1' },
  { company: 'Lexus', model: 'RZ300E', frame: 'XEBM10' },
  { company: 'Lexus', model: 'RZ450E', frame: 'XEBM15' },
  { company: 'MAZDA', model: 'MX-30', frame: 'DRH3P' },
  { company: 'MITSUBISHI', model: 'i-MiEV', frame: 'U68V, U67V, U69V' },
  { company: 'Mercedes Benz', model: 'EQC', frame: '293890' },
  { company: 'Mercedes Benz', model: 'EQB', frame: '243601C, 243612C' },
  { company: 'Mercedes Benz', model: 'EQE', frame: '295121, 295153' },
  { company: 'Mercedes Benz', model: 'EQS)', frame: '297123, 297155' },
  { company: 'Mercedes Benz', model: 'EQS SUV', frame: '296624, 296644' },
  { company: 'Mercedes Benz', model: 'EQA', frame: '243701CN, 243701C' },
  {
    company: 'TESLA',
    model: 'Model 3',
    frame:
      '3L13, 3L13B, 3L13T, -3L13T-, 3L23, 3L23B, 3L23P, -3L23P-, 3L23PB, 3L23PT, 3L23T, 3L24PT',
  },
  {
    company: 'TESLA',
    model: 'Model S',
    frame: 'SL1S, SL1S2A, SL1SA, SL2S, SL2S2A, SL2SA, SLA, SWL2S2A',
  },
  { company: 'TESLA', model: 'Model X', frame: 'XLA, XLP' },
  {
    company: 'TESLA',
    model: 'OTHER',
    frame:
      '3L13, 3L13B, 3L13T, -3L13T-, 3L23, 3L23B, 3L23P, -3L23P-, 3L23PB, 3L23PT, 3L23T, 3L24PT, XLA, XLP, SL1S, SL1S2A, SL1SA, SL2S, SL2S2A, SL2SA, SLA, SWL2S2A',
  },
  { company: 'TOYOTA', model: 'BZ4X', frame: 'XEAM10, YEAM15' },
  { company: 'VOLKSWAGEN', model: 'ID.4', frame: 'E2EBJ, E2EBJ' },
  { company: 'Volvo', model: 'XC40', frame: 'XE400RXCE' },
  { company: 'NISSAN', model: 'NV200', frame: 'VME0' },
];
