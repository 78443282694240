import { ConfigService, Currency, CurrencyService, MutationHandlingServiceV2 } from '@akebono/core';
import { ILanguage } from '@akebono/core/lib/const';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { QueryRef } from 'apollo-angular';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, of, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { getTypeSort, TEST_BID, TEST_BID_ID } from 'src/app/const';

import {
  AutoCurrentUserBidsFilter,
  Bid,
  BidsGQL,
  BidsQuery,
  BidsQueryVariables,
  BidStatusEnum,
  BidUpdateGQL,
  LotStatusEnum,
  LotTranslation,
  UserRequestTypeEnum,
} from '../../modules/graphql/service/graphql-auto-main-service';
import { CreateUserRequestModalComponent } from '../../modules/shared-components/create-user-request-modal/create-user-request-modal.component';
import { DriverService } from '../../modules/shared-components/driver/driver.service';
import { Config } from 'driver.js';

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss'],
})
export class BidsComponent implements OnInit, OnDestroy {
  currentCurrency$: Observable<Currency> = null;
  currentUserId: string = null;
  isCurrentUserOfficeWorldwide: boolean = null;
  bids: Bid[] = [];
  expandSet = new Set<string>();
  setOfCheckedId = new Set<string>();
  checked = false;
  indeterminate = false;
  approvingParticipations = of(false);
  isAwaitingParticipationMode = false;

  loading = true;

  filter?: AutoCurrentUserBidsFilter = null;

  pageIndex = 1;
  pageSize = 20;
  total = 0;

  currentLanguage$: Observable<ILanguage>;

  private queryRef?: QueryRef<BidsQuery, BidsQueryVariables>;
  private readonly destroy$ = new Subject<void>();
  conditions: BidsQuery['bidConditions'];
  counters = {};
  tempBids: Bid[] = [];

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private bidsGQL: BidsGQL,
    private translate: TranslateService,
    protected configService: ConfigService,
    private modalService: NzModalService,
    private currencyService: CurrencyService,
    private mhs: MutationHandlingServiceV2,
    private driverService: DriverService,
    private transService: TranslateService,
    private bidUpdateGQL: BidUpdateGQL,
    private cdr: ChangeDetectorRef,
  ) {
    this.currentLanguage$ = this.configService.getCurrentLanguage$();
  }

  ngOnInit(): void {
    this.title.setTitle('ECar JP Lots');

    this.currentCurrency$ = this.currencyService.currentCurrency$;

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.pageIndex = +params.pageIndex || 1;
      this.pageSize = +params.pageSize || 20;
      this.isAwaitingParticipationMode = params.tab === 'translated';

      const sort = {};

      if (params.sort && params.sortType) {
        sort[params.sort] = {
          direction: params.sortType,
        };
      }

      this.filter = {
        bidLotAuctionDate: {
          gte: params.bidLotAuctionDateFrom
            ? DateTime.fromJSDate(new Date(params.bidLotAuctionDateFrom), { zone: 'UTC' })
                .startOf('day')
                .toISO()
            : null,
          lte: params.bidLotAuctionDateTo
            ? DateTime.fromJSDate(new Date(params.bidLotAuctionDateTo), { zone: 'UTC' })
                .endOf('day')
                .toISO()
            : null,
        },
        bidLotAuctionName: {
          like: params.auctionName,
        },
        bidCondition: {
          eq: params.condition,
        },
        bidGroupLetter: {
          eq: params.groupLetter,
        },
        bidLotCompanyEn: {
          eq: params.company,
        },
        bidLotModelEn: {
          eq: params.model,
        },
        bidLotModelTypeEn: {
          eq: params.modelType,
        },
        bidLotCode: {
          eq: params.code,
        },
      };

      this.changeFilterByTab(params.tab);
      this.fetchBids(sort);
    });
  }

  private changeFilterByTab(tab: string = 'all'): void {
    switch (tab) {
      case 'all':
        this.filter.bidStatus = { eq: null };
        break;
      case 'initial':
        this.filter.bidStatus = { eq: BidStatusEnum.Initial };
        break;
      case 'translated':
        this.filter.bidIsParticipationAccepted = { eq: null };
        this.filter.bidCreateTranslation = { eq: true };
        this.filter.bidLotTranslationsCount = { gte: 1 };
        this.filter.bidStatus = { eq: BidStatusEnum.Initial };
        break;
      case 'confirmed':
        this.filter.bidStatus = { eq: BidStatusEnum.Confirmed };
        break;
      case 'won':
        this.filter.bidStatus = { eq: BidStatusEnum.Won };
        break;
      case 'canceled':
        this.filter.bidStatus = { eq: BidStatusEnum.Canceled };
        break;
    }
  }

  private fetchBids(sort: {}): void {
    this.loading = true;

    const variables: BidsQueryVariables = {
      first: this.pageSize,
      offset: (this.pageIndex - 1) * this.pageSize,
      filter: this.filter,
      sort: sort,
      bidLotAuctionDateFrom: this.filter.bidLotAuctionDate.gte,
      bidLotAuctionDateTo: this.filter.bidLotAuctionDate.lte,
    };

    if (this.queryRef) {
      this.queryRef.refetch(variables).finally(() => (this.loading = false));
    } else {
      this.queryRef = this.bidsGQL.watch(variables);
      this.queryRef.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(
        (result) => {
          this.loading = false;
          console.log('bidsGQL Query result =>', result);

          this.counters = {
            all: result?.data?.currentUser?.allBidsCount?.total,
            initial: result?.data?.currentUser?.initialBidsCount?.total,
            translated: result?.data?.currentUser?.translatedBidsCount?.total,
            confirmed: result?.data?.currentUser?.confirmedBidsCount?.total,
            won: result?.data?.currentUser?.wonBidsCount?.total,
            canceled: result?.data?.currentUser?.canceledBidsCount?.total,
          };
          this.conditions = result?.data?.bidConditions;
          this.bids = result?.data?.currentUser?.bids?.nodes;
          this.currentUserId = result?.data?.currentUser?.id;
          this.isCurrentUserOfficeWorldwide = result?.data?.currentUser?.officeBranch?.isWorldwide;
          this.total = result?.data?.currentUser?.bids?.total;
          this.refreshCheckedStatus();
        },
        (error) => {
          console.log('err', error);
        },
      );
    }
  }

  toggleComments(bidId: string): void {
    if (this.expandSet.has(bidId)) {
      this.expandSet.delete(bidId);
    } else {
      this.expandSet.add(bidId);
    }
  }

  openDeleteBidModal(bid: Bid): void {
    this.modalService.create({
      nzTitle: this.translate.instant('USER_REQUESTS.BID_DELETE_REQUEST'),
      nzContent: CreateUserRequestModalComponent,
      nzComponentParams: {
        type: UserRequestTypeEnum.BidDeleteRequest,
        refetch: false,
        input: {
          bidId: bid.id,
        },
      },
    });
  }

  getTranslationsByLanguage(bid: Bid, language?: ILanguage): LotTranslation[] {
    const currentLangTranslations = bid.lot.translations.filter(
      (tr) => tr.language === language.localeId,
    );

    if (currentLangTranslations.length) {
      return currentLangTranslations;
    } else {
      return bid.lot.translations;
    }
  }

  getLotStatus(bid: Bid): LotStatusEnum {
    if (bid.status === BidStatusEnum.Won && bid.lot.status === LotStatusEnum.Sold) {
      return LotStatusEnum.Won;
    } else {
      return bid.lot.status;
    }
  }

  updateCheckedSet(id: string, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  refreshCheckedStatus(): void {
    this.checked = this.setOfCheckedId.size === this.bids.length;
    this.indeterminate = this.setOfCheckedId.size > 0 && !this.checked;
  }

  onItemChecked(id: string, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.bids.forEach(({ id }) => this.updateCheckedSet(id, checked));
    this.refreshCheckedStatus();
  }

  scrollToTable(): void {
    document.querySelector('.table').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  changePageIndex(pageIndex: number): void {
    this.router.navigate([], {
      queryParams: {
        pageIndex,
        pageSize: this.pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.router.navigate([], {
      queryParams: {
        pageSize,
        pageIndex: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  approveParticipations(): void {
    this.approvingParticipations = this.setParticipations(true);
  }

  setParticipations(approve: boolean): Observable<boolean> {
    return this.mhs
      .mutateAll(
        this.bidUpdateGQL,
        Array.from(this.setOfCheckedId).map((id) => ({
          input: {
            id: id,
            isParticipationAccepted: approve,
          },
        })),
      )
      .loading.pipe(finalize(() => this.refreshCheckedStatus()));
  }

  sortEvent(sort: { key: string; value: string }): void {
    this.router.navigate(['bids'], {
      queryParams: {
        sort: sort.key,
        sortType: getTypeSort(sort.value),
      },
      queryParamsHandling: 'merge',
    });
  }

  changeMainDataToFakeForGuide(): void {
    if (!this.bids.some((item) => item.id === TEST_BID_ID)) {
      this.tempBids = this.bids;
      this.bids = [TEST_BID];
    }
  }

  startDriver(): void {
    const config: Config = {
      prevBtnText: this.transService.instant('DRIVER_CONFIG.PREV_BTN'),
      nextBtnText: this.transService.instant('DRIVER_CONFIG.NEXT_BTN'),
      doneBtnText: this.transService.instant('DRIVER_CONFIG.DONE_BTN'),
      onDestroyed: () => {
        this.bids = [...this.tempBids];
        this.driverService.reset(true);
      },
    };

    this.driverService.addStep(6, {
      popover: {
        title: this.translate.instant('LOT_INFO.GUIDE.FINISH.TITLE'),
        description: this.translate.instant('LOT_INFO.GUIDE.FINISH.DESCR'),
        popoverClass: 'bids',
      },
    });

    this.changeMainDataToFakeForGuide();
    this.cdr.detectChanges();
    this.driverService.setConfig(config);
    this.driverService.setDriverName('bids');
    this.driverService.start();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
