import { AgreementsRouterInterceptorService, QueryHandlingService } from '@akebono/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BidManualModalComponent } from 'src/app/components/bid-manual-modal/bid-manual-modal.component';
import {
  ExchangeRateFieldsFragment,
  ExchangeRateGQL,
  ExchangeRateQuery,
  ExchangeRateQueryVariables,
} from 'src/app/modules/graphql/service/graphql-exchange-rate-service';
import { SettingsModalComponent } from 'src/app/modules/shared-components/settings-modal/components/settings-modal/settings-modal.component';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
  selector: 'app-auctions-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
  clock$: Observable<Date>;
  isMobile$: Observable<boolean>;
  isDesktop$: Observable<boolean>;

  mobileMenuOpened = false;

  exchangeRates$: Observable<{
    jpyToRub: number;
    jpyToUsd: number;
    jpyToEur: number;
  }>;
  loading$: Observable<boolean>;

  translateFn = (key: string): string => {
    if (key) {
      return this.translate.instant(key);
    }
  };

  DateTime = DateTime;

  constructor(
    private location: Location,
    public layoutService: LayoutService,
    private translate: TranslateService,
    private modalService: NzModalService,
    breakpointObserver: BreakpointObserver,
    private agreementsRouterInterceptorService: AgreementsRouterInterceptorService,
    private exchangeRateGQL: ExchangeRateGQL,
    private qhs: QueryHandlingService,
  ) {
    const fetchRateQueryRef = this.qhs.fetch<ExchangeRateQuery, ExchangeRateQueryVariables>(
      this.exchangeRateGQL,
    );

    this.exchangeRates$ = fetchRateQueryRef?.data.pipe(
      map((exchangeRateData) => ({
        jpyToRub: this.calculateExchangeRate(exchangeRateData?.rubToJpy),
        jpyToUsd: exchangeRateData?.jpyToUsd?.sell ?? 0,
        jpyToEur: exchangeRateData?.jpyToEur?.sell ?? 0,
      })),
      shareReplay(1),
    );

    this.loading$ = fetchRateQueryRef.loading;

    this.isMobile$ = breakpointObserver.observe(['(max-width: 768px)']).pipe(
      map((state) => state.matches),
      shareReplay(1),
    );
    this.isDesktop$ = this.isMobile$.pipe(map((state) => !state));
  }

  ngOnInit(): void {
    this.clock$ = timer(0, 1000).pipe(map(() => new Date()));
    this.agreementsRouterInterceptorService.startIntercepting();
  }

  back(): void {
    const history = this.location.getState();
    const currentPath = this.location.path().split('/');

    if ((history === null || history['navigationId'] === 1) && currentPath.includes('lot')) {
      window.close();
    } else {
      this.location.back();
    }
  }

  createLotManually(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('LOT_MANUALLY'),
      nzContent: BidManualModalComponent,
      nzWidth: '550px',
    });
  }

  notificationSettings(): void {
    this.modalService.create({
      nzTitle: this.translate.instant('SETTINGS.TITLE'),
      nzContent: SettingsModalComponent,
    });
  }

  calculateExchangeRate(exchangeRate: ExchangeRateFieldsFragment | null | undefined): number {
    if (!exchangeRate?.nominal || !exchangeRate?.sell || exchangeRate.sell === 0) {
      return 0;
    }
    return +(exchangeRate.nominal / exchangeRate.sell).toFixed(2);
  }
}
