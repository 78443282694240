import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LotNoteComponent } from './lot-note.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { TranslateModule } from '@ngx-translate/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzWaveModule } from 'ng-zorro-antd/core/wave';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzGridModule } from 'ng-zorro-antd/grid';

@NgModule({
  declarations: [LotNoteComponent],
  imports: [
    CommonModule,
    NzCollapseModule,
    TranslateModule,
    NzButtonModule,
    NzIconModule,
    NzPopoverModule,
    NzWaveModule,
    NzPopconfirmModule,
    NzGridModule,
  ],
  exports: [LotNoteComponent],
})
export class LotNoteModule {}
