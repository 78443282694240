import { BirthdayGreetingsService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Alert, AlertTargetEnum } from 'src/app/modules/graphql/service/graphql-auto-main-service';
import { AutoCompaniesGQL } from 'src/app/modules/graphql/service/graphql-cars-default-service';
import { AlertService } from 'src/app/modules/shared-components/alerts-list/alert.service';
import { ConnectTelegramService } from 'src/app/modules/shared-components/connect-telegram/connect-telegram.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  topCompanies = ['1', '2', '3', '4', '5', '6', '7', '9', '24', '14'];
  onlyTopCompanies: any[] = [];
  remainingCompanies: { [key: string]: any[] } = {};
  companies: any[] = [];
  columns: any[] = [];
  loading = true;
  alerts$: Observable<Alert[]>;

  private destroy$ = new Subject<void>();

  constructor(
    private title: Title,
    private autoCompaniesGQL: AutoCompaniesGQL,
    private alertService: AlertService,
    private connectTelegramService: ConnectTelegramService,
    private birthdayGreetingsService: BirthdayGreetingsService,
  ) {
    this.title.setTitle('ECar JP');
  }

  ngOnInit(): void {
    this.loadData();
    this.alerts$ = this.alertService.getAlerts(null, AlertTargetEnum.MainPage);
    this.birthdayGreetingsService.checkBirthday();
    this.connectTelegramService
      .watch('settings', 'telegram')
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  loadData(): void {
    this.autoCompaniesGQL
      .fetch({}, { fetchPolicy: 'cache-first' })
      .pipe(
        map((result) => this.processCompanyData(result.data.autoCompanies.nodes)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.loading = false;
        this.columns = this.getCompaniesDividedInCols();
      });
  }

  processCompanyData(companies: any[]): void {
    companies.forEach((company) => {
      if (company.companyId !== '0') {
        if (this.topCompanies.includes(company.companyId)) {
          this.onlyTopCompanies.push(company);
        } else {
          const letter = company.name[0];
          this.remainingCompanies[letter] = this.remainingCompanies[letter] || [];
          this.remainingCompanies[letter].push(company);
        }
      }
    });
    this.onlyTopCompanies.sort();
    this.companies = Object.keys(this.remainingCompanies)
      .sort()
      .map((key) => ({
        key,
        items: this.remainingCompanies[key],
      }));
  }

  getCompaniesDividedInCols() {
    const colCount = Math.ceil(this.companies.length / 5);
    const cols = Array.from({ length: colCount }, () => ({ key: '', items: [] }));

    this.companies.forEach((company, index) => {
      const colIndex = Math.floor(index / 5);
      cols[colIndex].items.push(company);
    });

    return cols;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
